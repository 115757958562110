/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Draft
 * 
 * 1 = Issued
 * 
 * 2 = Partial
 * 
 * 3 = Paid
 */
export enum PurchaseOrderStatus {
  Draft = 0,
  Issued = 1,
  Partial = 2,
  Paid = 3
}
