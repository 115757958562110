import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CurrencyType, MilestoneNotificationDto } from "app/api/models";
import { TimeUtil } from "app/core/utils/time-utils";

@Component({
    selector       : 'milestone-notification-dialog',
    templateUrl    : './milestone-notification-dialog.component.html',
    styles         : [`
        ::ng-deep .notification-popup-cont .mat-mdc-form-field-infix {
            min-height: 20px !important;
        }
    `]
})
export class MilestoneNotificationDialogComponent implements OnInit
{
    public milestoneForm: FormGroup;

    public milestone: MilestoneNotificationDto;
    public changingDate: boolean;

    constructor(
        public dialogRef: MatDialogRef<MilestoneNotificationDialogComponent>,
        private _formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {milestone: MilestoneNotificationDto}
    )
    {}

    ngOnInit(): void
    {
        this.milestone = this.data.milestone;

        this.buildForm();
    }

    buildForm() {
        this.milestoneForm = this._formBuilder.group({
          vatApplicable           : [this.milestone.vatApplicable             ?? 'Yes/No'],
          poRef                   : [this.milestone.poRef                     ?? ''],
          date                    : [this.milestone.date                      ?? ''],
          paymentTerms            : [this.milestone.paymentTerms              ?? ''],
          notes                   : [this.milestone.notes                     ?? ''],
        });

        this.cdr.detectChanges();
    }
    
    submitMilestone() {
        var formVals = this.milestoneForm.value;

        this.milestone.vatApplicable = formVals.vatApplicable;
        this.milestone.poRef = formVals.poRef;
        this.milestone.paymentTerms = formVals.paymentTerms;
        this.milestone.notes = formVals.notes;

        this.dialogRef.close({milestone: this.milestone, submit: true});
    }

    getCurrencyTypeText(type: CurrencyType) {
        switch(type) {
            case CurrencyType.GBP: return '£ GBP';
            case CurrencyType.USD: return '$ USD';
            case CurrencyType.EUR: return '€ EUR';
            default: return 'N/A';
        }
    }

    GetCurrencyPipeValue = () => 
        this.milestone?.currencyType == CurrencyType.USD ? 'USD'
        :  this.milestone?.currencyType == CurrencyType.EUR ? 'EUR'
        : 'GBP';
        
    usesAlternativeCurrency = () => !!this.milestone?.currencyType;

    saveDate() {
        this.milestone.date = TimeUtil.setTo1amUTC(new Date(this.milestoneForm.value.date)).toISOString();
        
        this.dialogRef.close({milestone: this.milestone, submit: false});
    }

    close() {
        this.dialogRef.close(null);
    }
}
