<div style="min-width: 40vw; max-width: 650px;">
    <form [formGroup]="milestoneForm">

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Invoice Milestone Reminder</div>
        </div>
        
        <div class="grid sm:grid-cols-4 gap-6 w-full dialog-content-height-scroll">
            
            <div class="sm:col-span-4 grid sm:grid-cols-4 gap-x-6 gap-y-4 w-full mt-8 notification-popup-cont">

                <ng-container *ngIf="!changingDate">

                    <!-- Job Name: Ancient Apocalypse II
                    Total Project Budget: £325,000
                    Stage of Invoice (ie. 1st 50%, 2nd 50%): third invoice – 30%
                    Job Number: HC2477
                    Description of work (scope of work): Graphics and VFX for Series 2 - full 
                    Amount to be invoiced (not incl VAT): £97,500
                    Currency: £ GBP
                    VAT applicable: YES
                    Amount to be invoiced (incl VAT): £117,000
                    Client: ITN
                    Contact Name: Amber.Lambert@itn.co.uk
                    P.O. Ref: As per email dated 4 Sept -->


                    <div class="sm:col-span-4"><strong>Job Name: </strong>{{milestone.projectName}}</div>
                    <div class="sm:col-span-4"><strong>Total Project Budget: </strong>{{milestone.projectBudget | currency:'GBP'}}</div>
                    <div class="sm:col-span-4"><strong>Stage of Invoice: </strong>Stage {{milestone.stage}}</div> <!--- {{milestone.percentage * 100 | number:'1.0-2'}}%-->
                    <div class="sm:col-span-4"><strong>Project Ref: </strong>{{milestone.projectRef}}</div>
                    <div class="sm:col-span-4"><strong>Description of work: </strong>{{milestone.description}}</div>
                    <div class="sm:col-span-4">
                        <strong>Amount to be invoiced (not incl. VAT): </strong>
                        {{milestone.fixedAmount || milestone.amount | currency:'GBP'}}
                        <span *ngIf="usesAlternativeCurrency()">({{(milestone.fixedAmount || milestone.amount) * (milestone.fixedCurrencyRate || milestone.currencyRate) | currency:GetCurrencyPipeValue()}})</span>
                    </div>
                    <div class="sm:col-span-4">
                        <strong>Currency: </strong>

                        <mat-slide-toggle
                            *ngIf="!!this.milestone?.currencyType"
                            class="ml-2"
                            style="height: 20px !important"
                            [color]="'primary'"
                            [formControlName]="'useAltCurrency'">
                            {{getCurrencyTypeText(milestoneForm?.controls['useAltCurrency'].value ? milestone.currencyType : CurrencyType.GBP)}}
                        </mat-slide-toggle>

                        <span *ngIf="!this.milestone?.currencyType">{{getCurrencyTypeText(CurrencyType.GBP)}}</span>
                    </div>

                    <div class="sm:col-span-4 flex flex-row items-center gap-2">
                        <strong>VAT Applicable: </strong>
                        <!-- <mat-form-field
                            class="fuse-mat-dense"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'"> -->
                            <mat-slide-toggle
                                style="height: 20px !important"
                                [color]="'primary'"
                                [formControlName]="'vatApplicable'">
                            </mat-slide-toggle>
                            <!-- <input
                                style="height: 20px !important"
                                [formControlName]="'vatApplicable'"
                                matInput> -->
                        <!-- </mat-form-field> -->
                    </div>

                    <div class="sm:col-span-4">
                        <strong>Amount to be invoiced (incl. VAT): </strong>
                        {{(milestone.fixedAmount || milestone.amount) * (milestoneForm?.controls['vatApplicable'].value ? 1.2 : 1) | currency:'GBP'}}
                        <span *ngIf="usesAlternativeCurrency()">({{(milestone.fixedAmount || milestone.amount) * (milestoneForm?.controls['vatApplicable'].value ? 1.2 : 1) * (milestone.fixedCurrencyRate || milestone.currencyRate) | currency:GetCurrencyPipeValue()}})</span>
                    </div>

                    
                    <div class="sm:col-span-4 flex flex-row items-center gap-2"><strong>Client: </strong>

                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-select
                                [formControlName]="'companyName'"
                                [panelClass]="'w-full min-w-40 h-auto max-h-72'"
                                disableOptionCentering
                                (selectionChange)="companyChanged()">
                                <ng-container *ngFor="let company of companies">
                                    <mat-option [value]="company.name">
                                        <div class="font-medium">{{company.name}}</div>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                        <!-- {{milestone.companyName}} -->
                    </div>

                    <div class="sm:col-span-4 flex flex-row items-center gap-2"><strong>Contact: </strong>

                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-select
                                [formControlName]="'mainContactEmail'"
                                [panelClass]="'w-full min-w-40 h-auto max-h-72'"
                                disableOptionCentering>
                                <ng-container *ngFor="let email of getEmails()">
                                    <mat-option [value]="email">
                                        <div class="font-medium">{{email}}</div>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                        <!-- {{milestone.mainContactEmail}} -->
                    </div>


                    <div class="sm:col-span-4 flex flex-row items-center gap-2 whitespace-pre"><strong>Invoice Address: </strong>

                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                            <mat-select
                                [formControlName]="'invoiceAddressId'"
                                [panelClass]="'w-full min-w-40 h-auto max-h-72'"
                                disableOptionCentering>
                                <ng-container *ngFor="let address of getAddresses()">
                                    <mat-option [value]="address.projectAddressId">
                                        <div class="font-medium">{{address.addressOne}}</div>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        
                        <!-- {{milestone.invoiceAddress.addressOne}} -->
                    </div>


                    <div class="sm:col-span-4 flex flex-row items-center gap-2">
                        <strong>PO Ref: </strong>
                        <mat-form-field
                            class="fuse-mat-dense"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'">
                            <input
                                style="height: 20px !important"
                                [formControlName]="'poRef'"
                                matInput>
                        </mat-form-field>
                    </div>
                    
                    <div class="sm:col-span-4 flex flex-row items-center gap-2">
                        <strong>Payment Terms: </strong>
                        <mat-form-field
                            class="fuse-mat-dense flex-1"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'">
                            <input
                                style="height: 20px !important"
                                [formControlName]="'paymentTerms'"
                                matInput>
                        </mat-form-field>
                    </div>
                    
                    <div class="sm:col-span-4 flex flex-row gap-2">
                        <strong>Notes: </strong>
                        <mat-form-field
                            class="fuse-mat-dense flex-1"
                            [subscriptSizing]="'dynamic'">
                            <textarea
                                [formControlName]="'notes'"
                                matInput
                                cdkTextareaAutosize
                                [cdkAutosizeMinRows]="1">
                            </textarea>
                        </mat-form-field>
                    </div>

                </ng-container>

                <ng-container *ngIf="changingDate">
                    
                    <div class="sm:col-span-4 flex flex-row items-center gap-2">
                        <strong>Date: </strong>
                        <mat-form-field
                            class="fuse-mat-dense"
                            [subscriptSizing]="'dynamic'">
                            <input
                                [formControlName]="'date'"
                                [matDatepicker]="notifPicker"
                                matInput>
                            <mat-datepicker-toggle matIconSuffix [for]="notifPicker"></mat-datepicker-toggle>
                            <mat-datepicker #notifPicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                </ng-container>

            </div>
        </div>

        <!-- Divider -->
        <div class="mt-11 mb-10 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
            <button
                [style.margin-right]="changingDate ? '' : 'auto'"
                (click)="close()"
                mat-stroked-button
                type="button">
                Cancel
            </button>

            <button
                *ngIf="!changingDate"
                (click)="changingDate = !changingDate"
                class="ml-4"
                mat-stroked-button
                type="button">
                Change Due Date
            </button>

            <button
                *ngIf="!changingDate"
                (click)="submitMilestone()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'"
                [disabled]="!milestoneForm?.valid">
                Submit to Accounts
            </button>
            
            <button
                *ngIf="changingDate"
                (click)="saveDate()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'"
                [disabled]="!milestoneForm.controls['date'].valid || milestoneForm.controls['date'].pristine">
                Save
            </button>
        </div>
    </form>

</div>
