/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { MonteithInvoiceDto } from '../models/monteith-invoice-dto';
import { MonteithInvoiceDtoBaseResponse } from '../models/monteith-invoice-dto-base-response';
import { MonteithInvoiceDtoListBaseResponse } from '../models/monteith-invoice-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class MonteithService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMonteithGetAllGet
   */
  static readonly ApiMonteithGetAllGetPath = '/api/Monteith/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithGetAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithGetAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithGetAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<MonteithInvoiceDtoListBaseResponse> {

    return this.apiMonteithGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>) => r.body as MonteithInvoiceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithGetAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithGetAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithGetAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<MonteithInvoiceDtoListBaseResponse> {

    return this.apiMonteithGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoListBaseResponse>) => r.body as MonteithInvoiceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiMonteithCreateMonteithPost
   */
  static readonly ApiMonteithCreateMonteithPostPath = '/api/Monteith/create-monteith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithCreateMonteithPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithCreateMonteithPost$Plain$Response(params?: {
    body?: MonteithInvoiceDto
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithCreateMonteithPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithCreateMonteithPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithCreateMonteithPost$Plain(params?: {
    body?: MonteithInvoiceDto
  }): Observable<MonteithInvoiceDtoBaseResponse> {

    return this.apiMonteithCreateMonteithPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoBaseResponse>) => r.body as MonteithInvoiceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithCreateMonteithPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithCreateMonteithPost$Json$Response(params?: {
    body?: MonteithInvoiceDto
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithCreateMonteithPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithCreateMonteithPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithCreateMonteithPost$Json(params?: {
    body?: MonteithInvoiceDto
  }): Observable<MonteithInvoiceDtoBaseResponse> {

    return this.apiMonteithCreateMonteithPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoBaseResponse>) => r.body as MonteithInvoiceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiMonteithUpdateMonteithPut
   */
  static readonly ApiMonteithUpdateMonteithPutPath = '/api/Monteith/update-monteith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithUpdateMonteithPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithUpdateMonteithPut$Plain$Response(params?: {
    body?: MonteithInvoiceDto
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithUpdateMonteithPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithUpdateMonteithPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithUpdateMonteithPut$Plain(params?: {
    body?: MonteithInvoiceDto
  }): Observable<MonteithInvoiceDtoBaseResponse> {

    return this.apiMonteithUpdateMonteithPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoBaseResponse>) => r.body as MonteithInvoiceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithUpdateMonteithPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithUpdateMonteithPut$Json$Response(params?: {
    body?: MonteithInvoiceDto
  }): Observable<StrictHttpResponse<MonteithInvoiceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithUpdateMonteithPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonteithInvoiceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithUpdateMonteithPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMonteithUpdateMonteithPut$Json(params?: {
    body?: MonteithInvoiceDto
  }): Observable<MonteithInvoiceDtoBaseResponse> {

    return this.apiMonteithUpdateMonteithPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MonteithInvoiceDtoBaseResponse>) => r.body as MonteithInvoiceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiMonteithToggleArchiveDelete
   */
  static readonly ApiMonteithToggleArchiveDeletePath = '/api/Monteith/toggle-archive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithToggleArchiveDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithToggleArchiveDelete$Plain$Response(params?: {
    monteithInvoiceId?: number;
    archive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithToggleArchiveDeletePath, 'delete');
    if (params) {
      rb.query('monteithInvoiceId', params.monteithInvoiceId, {});
      rb.query('archive', params.archive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithToggleArchiveDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithToggleArchiveDelete$Plain(params?: {
    monteithInvoiceId?: number;
    archive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiMonteithToggleArchiveDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithToggleArchiveDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithToggleArchiveDelete$Json$Response(params?: {
    monteithInvoiceId?: number;
    archive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithToggleArchiveDeletePath, 'delete');
    if (params) {
      rb.query('monteithInvoiceId', params.monteithInvoiceId, {});
      rb.query('archive', params.archive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithToggleArchiveDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithToggleArchiveDelete$Json(params?: {
    monteithInvoiceId?: number;
    archive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiMonteithToggleArchiveDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiMonteithHardDeleteDelete
   */
  static readonly ApiMonteithHardDeleteDeletePath = '/api/Monteith/hard-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithHardDeleteDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithHardDeleteDelete$Plain$Response(params?: {
    monteithInvoiceId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithHardDeleteDeletePath, 'delete');
    if (params) {
      rb.query('monteithInvoiceId', params.monteithInvoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithHardDeleteDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithHardDeleteDelete$Plain(params?: {
    monteithInvoiceId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiMonteithHardDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonteithHardDeleteDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithHardDeleteDelete$Json$Response(params?: {
    monteithInvoiceId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MonteithService.ApiMonteithHardDeleteDeletePath, 'delete');
    if (params) {
      rb.query('monteithInvoiceId', params.monteithInvoiceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMonteithHardDeleteDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonteithHardDeleteDelete$Json(params?: {
    monteithInvoiceId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiMonteithHardDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
