<!-- Notifications button -->
<button
    mat-icon-button
    #notificationsOrigin
    (click)="openPanel()">
    <mat-icon class="mr-3" [svgIcon]="'custom:invoice-envelope'" [matBadge]="pendingMilestones.length" matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="!pendingMilestones.length"></mat-icon>
</button>


<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-2 pr-2 pl-4 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Overdue Milestones</div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of pendingMilestones; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 unread cursor-pointer" (click)="openMilestone(notification)">
                    <ng-container>
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:document-text'">
                        </mat-icon>
                    </div>
                    
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.projectName">
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="notification.projectName"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.description">
                            <div
                                class="line-clamp-2"
                                [innerHTML]="notification.description"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{notification.date | date:'EEE dd MMM, yyyy'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!pendingMilestones || !pendingMilestones.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon
                            class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have notifications, they will appear here.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
