import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";
// import { CustomPagingFooter } from "./custom-paging-footer/custom-paging-footer.component";
import { SharedMaterialModule } from "../shared-material.module";
import { SharedDirectivesModule } from "../directives/shared-directives.module";
import { TextFieldModule } from "@angular/cdk/text-field";
import { CdkScrollableModule } from "@angular/cdk/scrolling";
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";
import { CommentsComponent } from "./comments/comments.component";
import { MilestoneNotificationsComponent } from "./milestone-notifications/milestone-notifications.component";
import { MilestoneNotificationDialogComponent } from "./milestone-notifications/milestone-notification-dialog/milestone-notification-dialog.component";
// import { NgxDatatableModule } from "@swimlane/ngx-datatable";

const components = [
  // CustomPagingFooter,
  AppComfirmComponent,
  AppLoaderComponent,
  CommentsComponent,
  MilestoneNotificationsComponent,
  MilestoneNotificationDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialModule,
    SharedDirectivesModule,
    TextFieldModule,
    CdkScrollableModule,
    CdkDropList,
    CdkDropListGroup,
    CdkDrag,
    CdkDragHandle,
    // NgxDatatableModule,
  ],
  declarations: components,
  exports: components,
})
export class SharedComponentsModule {}
