/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { BudgetAdditionDto } from '../models/budget-addition-dto';
import { BudgetAdditionDtoBaseResponse } from '../models/budget-addition-dto-base-response';
import { BudgetDto } from '../models/budget-dto';
import { BudgetDtoBaseResponse } from '../models/budget-dto-base-response';
import { ProjectDeliverableDto } from '../models/project-deliverable-dto';
import { SavedCurrencyRateDto } from '../models/saved-currency-rate-dto';
import { SavedCurrencyRateDtoListBaseResponse } from '../models/saved-currency-rate-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class BudgetService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiBudgetBudgetGet
   */
  static readonly ApiBudgetBudgetGetPath = '/api/Budget/budget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetBudgetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetBudgetGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<BudgetDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetBudgetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BudgetDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetBudgetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetBudgetGet$Plain(params?: {
    projectId?: number;
  }): Observable<BudgetDtoBaseResponse> {

    return this.apiBudgetBudgetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BudgetDtoBaseResponse>) => r.body as BudgetDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetBudgetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetBudgetGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<BudgetDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetBudgetGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BudgetDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetBudgetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetBudgetGet$Json(params?: {
    projectId?: number;
  }): Observable<BudgetDtoBaseResponse> {

    return this.apiBudgetBudgetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BudgetDtoBaseResponse>) => r.body as BudgetDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetUpdateBudgetPut
   */
  static readonly ApiBudgetUpdateBudgetPutPath = '/api/Budget/update-budget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateBudgetPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateBudgetPut$Plain$Response(params?: {
    body?: BudgetDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateBudgetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateBudgetPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateBudgetPut$Plain(params?: {
    body?: BudgetDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateBudgetPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateBudgetPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateBudgetPut$Json$Response(params?: {
    body?: BudgetDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateBudgetPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateBudgetPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateBudgetPut$Json(params?: {
    body?: BudgetDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateBudgetPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetSwitchCostManagerPost
   */
  static readonly ApiBudgetSwitchCostManagerPostPath = '/api/Budget/switch-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetSwitchCostManagerPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetSwitchCostManagerPost$Plain$Response(params?: {
    budgetId?: number;
    costManagerId?: number;
    discount?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetSwitchCostManagerPostPath, 'post');
    if (params) {
      rb.query('budgetId', params.budgetId, {});
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('discount', params.discount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetSwitchCostManagerPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetSwitchCostManagerPost$Plain(params?: {
    budgetId?: number;
    costManagerId?: number;
    discount?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetSwitchCostManagerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetSwitchCostManagerPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetSwitchCostManagerPost$Json$Response(params?: {
    budgetId?: number;
    costManagerId?: number;
    discount?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetSwitchCostManagerPostPath, 'post');
    if (params) {
      rb.query('budgetId', params.budgetId, {});
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('discount', params.discount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetSwitchCostManagerPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetSwitchCostManagerPost$Json(params?: {
    budgetId?: number;
    costManagerId?: number;
    discount?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetSwitchCostManagerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetAddAdditionPost
   */
  static readonly ApiBudgetAddAdditionPostPath = '/api/Budget/add-addition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetAddAdditionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddAdditionPost$Plain$Response(params?: {
    body?: BudgetAdditionDto
  }): Observable<StrictHttpResponse<BudgetAdditionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetAddAdditionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BudgetAdditionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetAddAdditionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddAdditionPost$Plain(params?: {
    body?: BudgetAdditionDto
  }): Observable<BudgetAdditionDtoBaseResponse> {

    return this.apiBudgetAddAdditionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BudgetAdditionDtoBaseResponse>) => r.body as BudgetAdditionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetAddAdditionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddAdditionPost$Json$Response(params?: {
    body?: BudgetAdditionDto
  }): Observable<StrictHttpResponse<BudgetAdditionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetAddAdditionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BudgetAdditionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetAddAdditionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddAdditionPost$Json(params?: {
    body?: BudgetAdditionDto
  }): Observable<BudgetAdditionDtoBaseResponse> {

    return this.apiBudgetAddAdditionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BudgetAdditionDtoBaseResponse>) => r.body as BudgetAdditionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetUpdateAdditionPut
   */
  static readonly ApiBudgetUpdateAdditionPutPath = '/api/Budget/update-addition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateAdditionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateAdditionPut$Plain$Response(params?: {
    body?: BudgetAdditionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateAdditionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateAdditionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateAdditionPut$Plain(params?: {
    body?: BudgetAdditionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateAdditionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateAdditionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateAdditionPut$Json$Response(params?: {
    body?: BudgetAdditionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateAdditionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateAdditionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateAdditionPut$Json(params?: {
    body?: BudgetAdditionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateAdditionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetDeleteAdditionDelete
   */
  static readonly ApiBudgetDeleteAdditionDeletePath = '/api/Budget/delete-addition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetDeleteAdditionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetDeleteAdditionDelete$Plain$Response(params?: {
    budgetAdditionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetDeleteAdditionDeletePath, 'delete');
    if (params) {
      rb.query('budgetAdditionId', params.budgetAdditionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetDeleteAdditionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetDeleteAdditionDelete$Plain(params?: {
    budgetAdditionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetDeleteAdditionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetDeleteAdditionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetDeleteAdditionDelete$Json$Response(params?: {
    budgetAdditionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetDeleteAdditionDeletePath, 'delete');
    if (params) {
      rb.query('budgetAdditionId', params.budgetAdditionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetDeleteAdditionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetDeleteAdditionDelete$Json(params?: {
    budgetAdditionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetDeleteAdditionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetAddDeliverablePost
   */
  static readonly ApiBudgetAddDeliverablePostPath = '/api/Budget/add-deliverable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetAddDeliverablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddDeliverablePost$Plain$Response(params?: {
    body?: ProjectDeliverableDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetAddDeliverablePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetAddDeliverablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddDeliverablePost$Plain(params?: {
    body?: ProjectDeliverableDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetAddDeliverablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetAddDeliverablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddDeliverablePost$Json$Response(params?: {
    body?: ProjectDeliverableDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetAddDeliverablePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetAddDeliverablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetAddDeliverablePost$Json(params?: {
    body?: ProjectDeliverableDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetAddDeliverablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetUpdateDeliverablePost
   */
  static readonly ApiBudgetUpdateDeliverablePostPath = '/api/Budget/update-deliverable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateDeliverablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateDeliverablePost$Plain$Response(params?: {
    body?: ProjectDeliverableDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateDeliverablePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateDeliverablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateDeliverablePost$Plain(params?: {
    body?: ProjectDeliverableDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateDeliverablePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateDeliverablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateDeliverablePost$Json$Response(params?: {
    body?: ProjectDeliverableDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateDeliverablePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateDeliverablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateDeliverablePost$Json(params?: {
    body?: ProjectDeliverableDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateDeliverablePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetArchiveDeliverableDelete
   */
  static readonly ApiBudgetArchiveDeliverableDeletePath = '/api/Budget/archive-deliverable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetArchiveDeliverableDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetArchiveDeliverableDelete$Plain$Response(params?: {
    deliverableId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetArchiveDeliverableDeletePath, 'delete');
    if (params) {
      rb.query('deliverableId', params.deliverableId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetArchiveDeliverableDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetArchiveDeliverableDelete$Plain(params?: {
    deliverableId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetArchiveDeliverableDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetArchiveDeliverableDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetArchiveDeliverableDelete$Json$Response(params?: {
    deliverableId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetArchiveDeliverableDeletePath, 'delete');
    if (params) {
      rb.query('deliverableId', params.deliverableId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetArchiveDeliverableDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetArchiveDeliverableDelete$Json(params?: {
    deliverableId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetArchiveDeliverableDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetCurrencyRatesGet
   */
  static readonly ApiBudgetCurrencyRatesGetPath = '/api/Budget/currency-rates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetCurrencyRatesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetCurrencyRatesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetCurrencyRatesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetCurrencyRatesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetCurrencyRatesGet$Plain(params?: {
  }): Observable<SavedCurrencyRateDtoListBaseResponse> {

    return this.apiBudgetCurrencyRatesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>) => r.body as SavedCurrencyRateDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetCurrencyRatesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetCurrencyRatesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetCurrencyRatesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetCurrencyRatesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBudgetCurrencyRatesGet$Json(params?: {
  }): Observable<SavedCurrencyRateDtoListBaseResponse> {

    return this.apiBudgetCurrencyRatesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCurrencyRateDtoListBaseResponse>) => r.body as SavedCurrencyRateDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiBudgetUpdateCurrencyRatePost
   */
  static readonly ApiBudgetUpdateCurrencyRatePostPath = '/api/Budget/update-currency-rate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateCurrencyRatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateCurrencyRatePost$Plain$Response(params?: {
    body?: SavedCurrencyRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateCurrencyRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateCurrencyRatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateCurrencyRatePost$Plain(params?: {
    body?: SavedCurrencyRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateCurrencyRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBudgetUpdateCurrencyRatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateCurrencyRatePost$Json$Response(params?: {
    body?: SavedCurrencyRateDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetService.ApiBudgetUpdateCurrencyRatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBudgetUpdateCurrencyRatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBudgetUpdateCurrencyRatePost$Json(params?: {
    body?: SavedCurrencyRateDto
  }): Observable<BooleanBaseResponse> {

    return this.apiBudgetUpdateCurrencyRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
