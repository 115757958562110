<div style="width: 30vw;">
    <form [formGroup]="milestoneForm">

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Invoice Milestone Reminder</div>
        </div>
        
        <div class="grid sm:grid-cols-4 gap-6 w-full">
            
            <div class="sm:col-span-4 grid sm:grid-cols-4 gap-x-6 gap-y-4 w-full mt-8 notification-popup-cont">

                <ng-container *ngIf="!changingDate">

                    <!-- Job Name: Ancient Apocalypse II
                    Total Project Budget: £325,000
                    Stage of Invoice (ie. 1st 50%, 2nd 50%): third invoice – 30%
                    Job Number: HC2477
                    Description of work (scope of work): Graphics and VFX for Series 2 - full 
                    Amount to be invoiced (not incl VAT): £97,500
                    Currency: £ GBP
                    VAT applicable: YES
                    Amount to be invoiced (incl VAT): £117,000
                    Client: ITN
                    Contact Name: Amber.Lambert@itn.co.uk
                    P.O. Ref: As per email dated 4 Sept -->


                    <div class="sm:col-span-4"><strong>Job Name: </strong>{{milestone.projectName}}</div>
                    <div class="sm:col-span-4"><strong>Total Project Budget: </strong>{{milestone.projectBudget}}</div>
                    <div class="sm:col-span-4"><strong>Stage of Invoice: </strong>Stage {{milestone.stage}} - {{milestone.percentage * 100 | number:'1.0-2'}}%</div>
                    <div class="sm:col-span-4"><strong>Project Ref: </strong>{{milestone.projectRef}}</div>
                    <div class="sm:col-span-4"><strong>Description of work: </strong>{{milestone.description}}</div>
                    <div class="sm:col-span-4">
                        <strong>Amount to be invoiced (not incl. VAT): </strong>
                        {{milestone.fixedAmount || milestone.amount | currency:'GBP'}}
                        <span *ngIf="usesAlternativeCurrency()">({{(milestone.fixedAmount || milestone.amount) * (milestone.fixedCurrencyRate || milestone.currencyRate) | currency:GetCurrencyPipeValue()}})</span>
                    </div>
                    <div class="sm:col-span-4"><strong>Currency: </strong>{{getCurrencyTypeText(milestone.currencyType)}}</div>

                    <div class="sm:col-span-4 flex flex-row">
                        <strong>VAT Applicable: </strong>
                        <mat-form-field
                            class="fuse-mat-dense ml-1"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'">
                            <input
                                style="height: 20px !important"
                                [formControlName]="'vatApplicable'"
                                matInput>
                        </mat-form-field>
                    </div>

                    <div class="sm:col-span-4">
                        <strong>Amount to be invoiced (incl. VAT): </strong>
                        {{(milestone.fixedAmount || milestone.amount) * 1.2 | currency:'GBP'}}
                        <span *ngIf="usesAlternativeCurrency()">({{(milestone.fixedAmount || milestone.amount) * 1.2 * (milestone.fixedCurrencyRate || milestone.currencyRate) | currency:GetCurrencyPipeValue()}})</span>
                    </div>
                    <div class="sm:col-span-4"><strong>Client: </strong>{{milestone.company}}</div>
                    <div class="sm:col-span-4"><strong>{{milestone.useClientOverride ? 'Invoice' : 'Main'}} Contact: </strong>{{milestone.useClientOverride ? milestone.overrideMainContactEmail : milestone.mainContactEmail}}</div>

                    <div class="sm:col-span-4 flex flex-row">
                        <strong>PO Ref: </strong>
                        <mat-form-field
                            class="fuse-mat-dense ml-1"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'">
                            <input
                                style="height: 20px !important"
                                [formControlName]="'poRef'"
                                matInput>
                        </mat-form-field>
                    </div>
                    
                    <div class="sm:col-span-4 flex flex-row">
                        <strong>Payment Terms: </strong>
                        <mat-form-field
                            class="fuse-mat-dense mx-1 flex-1"
                            style="height: 21px !important"
                            [subscriptSizing]="'dynamic'">
                            <input
                                style="height: 20px !important"
                                [formControlName]="'paymentTerms'"
                                matInput>
                        </mat-form-field>
                    </div>
                    
                    <div class="sm:col-span-4 flex flex-row">
                        <strong>Notes: </strong>
                        <mat-form-field
                            class="fuse-mat-dense mx-1 flex-1"
                            [subscriptSizing]="'dynamic'">
                            <textarea
                                [formControlName]="'notes'"
                                matInput
                                cdkTextareaAutosize
                                [cdkAutosizeMinRows]="1">
                            </textarea>
                        </mat-form-field>
                    </div>

                </ng-container>

                <ng-container *ngIf="changingDate">
                    
                    <div class="sm:col-span-4 flex flex-row items-center">
                        <strong>Date: </strong>
                        <mat-form-field
                            class="fuse-mat-dense ml-1"
                            [subscriptSizing]="'dynamic'">
                            <input
                                [formControlName]="'date'"
                                [matDatepicker]="notifPicker"
                                matInput>
                            <mat-datepicker-toggle matIconSuffix [for]="notifPicker"></mat-datepicker-toggle>
                            <mat-datepicker #notifPicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                </ng-container>

            </div>
        </div>

        <!-- Divider -->
        <div class="mt-11 mb-10 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
            <button
                [style.margin-right]="changingDate ? '' : 'auto'"
                (click)="close()"
                mat-stroked-button
                type="button">
                Cancel
            </button>

            <button
                *ngIf="!changingDate"
                (click)="changingDate = !changingDate"
                class="ml-4"
                mat-stroked-button
                type="button">
                Change Due Date
            </button>

            <button
                *ngIf="!changingDate"
                (click)="submitMilestone()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'">
                Submit to Accounts
            </button>
            
            <button
                *ngIf="changingDate"
                (click)="saveDate()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'"
                [disabled]="!milestoneForm?.valid || milestoneForm?.pristine">
                Save
            </button>
        </div>
    </form>

</div>
