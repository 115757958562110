/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Celebrate
 * 
 * 1 = Cheer
 * 
 * 2 = Strong
 * 
 * 3 = Heart
 * 
 * 4 = Stars
 * 
 * 5 = Rocket
 */
export enum ReactionEmoji {
  Celebrate = 0,
  Cheer = 1,
  Strong = 2,
  Heart = 3,
  Stars = 4,
  Rocket = 5
}
