export class TimeUtil {

    public static getHoursText(hours: number) {
        if(!hours) return '0h'; //None';

        var hr = Math.trunc(hours);
        var mn = Math.round((hours % 1) * 60);

        return (hr > 0 ? `${hr}h ` : '') + (mn > 0 ? `${mn}m` : '');
    }

    public static getHoursFromHoursText(text: string) {
        if(!text.includes('h') && !text.includes('m')) {
            const dregex = /(\d+(.\d+)?)/g;
            const dfound = text.match(dregex);
            return dfound?.length ? dfound[0] : '0';
        }

        const hregex = /(\d+(.\d+)?)h/g;
        const hfound = text.match(hregex);
        var hours = hfound?.length ? hfound[0] : '0';

        const mregex = /(\d+)m/g;
        const mfound = text.match(mregex);
        var mins = mfound?.length ? mfound[0] : '0';

        if(!hfound && !mfound) return '0';

        return (Number.parseFloat(hours) + Number.parseInt(mins) / 60).toFixed(2);
    }

    public static addHours(date: Date, hours: number): Date {
        date.setTime(date.getTime() + (hours*60*60*1000));
        return date;
    }

    public static setTo1amUTC(date: Date) {
        if(date.getHours() == 0)
            TimeUtil.addHours(date, 1);
        date.setHours(2, 0, 0, 0);
        return date;
    }

    public static setTo2359UTC(date: Date) {
        TimeUtil.setTo1amUTC(date);
        date.setHours(23, 59, 0, 0);
        return date;
    }
    
    public static addDays(date: Date, days: number) {
        date.setTime(date.getTime() + (days*24*60*60*1000));
        return date;
    }

    public static addMonths(date: Date, months: number) {
        var previousMonth = date.getMonth();
        var newMonth = previousMonth + months;
        var newYear = newMonth >= 12 ? date.getFullYear() + 1 : date.getFullYear();

        var newDate = new Date(newYear, newMonth % 12, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }
    
    public static addYears(date: Date, years: number) {
        var newDate = new Date(date.getFullYear() + years, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }

}
