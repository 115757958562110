/* tslint:disable */
/* eslint-disable */

/**
 * 1 = LiveEventScreens
 * 
 * 2 = BroadcastProgramme
 * 
 * 3 = OnlineOnly
 * 
 * 4 = TVAdvertising
 * 
 * 5 = SocialMedia
 * 
 * 6 = Other
 */
export enum TransmissionFormat {
  LiveEventScreens = 1,
  BroadcastProgramme = 2,
  OnlineOnly = 3,
  TVAdvertising = 4,
  SocialMedia = 5,
  Other = 6
}
