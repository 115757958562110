import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
} from "@angular/common";

// SERVICES
// import { ThemeService } from "./services/theme.service";
// import { NavigationService } from "./services/navigation.service";
// import { RoutePartsService } from "./services/route-parts.service";
// import { AuthGuard } from "./guards/auth.guard";
// import { UserRoleGuard } from "./guards/user-role.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";

import { SharedComponentsModule } from "./components/shared-components.module";
// import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
// import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MAT_DATE_FORMATS } from "@angular/material/core";
// import { AuthGuard } from "./services/cookie-jwt-auth/guards/auth.guard";
// import { UserRoleGuard } from "./services/cookie-jwt-auth/guards/user-role.guard";

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    // SharedPipesModule,
    SharedDirectivesModule,
  ],
  providers: [
    // ThemeService,
    // NavigationService,
    // RoutePartsService,
    // AuthGuard,
    // UserRoleGuard,
    AppConfirmService,
    AppLoaderService,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: "en-GB",
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "GBP",
    },
    // {
    //   provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    //   useValue: { useUtc: true },
    // },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD/MM/YYYY",
        },
        display: {
          dateInput: "DD/MM/YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
  exports: [SharedComponentsModule, SharedDirectivesModule],
})
export class SharedModule {}
