/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { ContactCommentDto } from '../models/contact-comment-dto';
import { ContactCommentDtoBaseResponse } from '../models/contact-comment-dto-base-response';
import { ContactCommentDtoListBaseResponse } from '../models/contact-comment-dto-list-base-response';
import { ContactDto } from '../models/contact-dto';
import { ContactDtoBaseResponse } from '../models/contact-dto-base-response';
import { ContactDtoListBaseResponse } from '../models/contact-dto-list-base-response';
import { ContactDtoPagedResponseBaseResponse } from '../models/contact-dto-paged-response-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiContactPaginatedListGet
   */
  static readonly ApiContactPaginatedListGetPath = '/api/Contact/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactPaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ContactDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactPaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ContactDtoPagedResponseBaseResponse> {

    return this.apiContactPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoPagedResponseBaseResponse>) => r.body as ContactDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactPaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ContactDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactPaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactPaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ContactDtoPagedResponseBaseResponse> {

    return this.apiContactPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoPagedResponseBaseResponse>) => r.body as ContactDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactGetAllGet
   */
  static readonly ApiContactGetAllGetPath = '/api/Contact/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetAllGet$Plain$Response(params?: {
    includeLeads?: boolean;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactGetAllGetPath, 'get');
    if (params) {
      rb.query('includeLeads', params.includeLeads, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetAllGet$Plain(params?: {
    includeLeads?: boolean;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetAllGet$Json$Response(params?: {
    includeLeads?: boolean;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactGetAllGetPath, 'get');
    if (params) {
      rb.query('includeLeads', params.includeLeads, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetAllGet$Json(params?: {
    includeLeads?: boolean;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactSearchAllGet
   */
  static readonly ApiContactSearchAllGetPath = '/api/Contact/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
    groupByCompany?: boolean;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
      rb.query('groupByCompany', params.groupByCompany, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
    groupByCompany?: boolean;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
    groupByCompany?: boolean;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
      rb.query('groupByCompany', params.groupByCompany, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
    groupByCompany?: boolean;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactSearchCompanyContactsGet
   */
  static readonly ApiContactSearchCompanyContactsGetPath = '/api/Contact/search-company-contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactSearchCompanyContactsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchCompanyContactsGet$Plain$Response(params?: {
    companyId?: number;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactSearchCompanyContactsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactSearchCompanyContactsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchCompanyContactsGet$Plain(params?: {
    companyId?: number;
    searchTerm?: string;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactSearchCompanyContactsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactSearchCompanyContactsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchCompanyContactsGet$Json$Response(params?: {
    companyId?: number;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<ContactDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactSearchCompanyContactsGetPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactSearchCompanyContactsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactSearchCompanyContactsGet$Json(params?: {
    companyId?: number;
    searchTerm?: string;
  }): Observable<ContactDtoListBaseResponse> {

    return this.apiContactSearchCompanyContactsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoListBaseResponse>) => r.body as ContactDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactGetContactPost
   */
  static readonly ApiContactGetContactPostPath = '/api/Contact/get-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactGetContactPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetContactPost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<ContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactGetContactPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactGetContactPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetContactPost$Plain(params?: {
    id?: number;
  }): Observable<ContactDtoBaseResponse> {

    return this.apiContactGetContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoBaseResponse>) => r.body as ContactDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactGetContactPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetContactPost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<ContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactGetContactPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactGetContactPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactGetContactPost$Json(params?: {
    id?: number;
  }): Observable<ContactDtoBaseResponse> {

    return this.apiContactGetContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoBaseResponse>) => r.body as ContactDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactAddContactPost
   */
  static readonly ApiContactAddContactPostPath = '/api/Contact/add-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactAddContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddContactPost$Plain$Response(params?: {
    body?: ContactDto
  }): Observable<StrictHttpResponse<ContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactAddContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactAddContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddContactPost$Plain(params?: {
    body?: ContactDto
  }): Observable<ContactDtoBaseResponse> {

    return this.apiContactAddContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoBaseResponse>) => r.body as ContactDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactAddContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddContactPost$Json$Response(params?: {
    body?: ContactDto
  }): Observable<StrictHttpResponse<ContactDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactAddContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactAddContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddContactPost$Json(params?: {
    body?: ContactDto
  }): Observable<ContactDtoBaseResponse> {

    return this.apiContactAddContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactDtoBaseResponse>) => r.body as ContactDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactUpdateContactPost
   */
  static readonly ApiContactUpdateContactPostPath = '/api/Contact/update-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactUpdateContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateContactPost$Plain$Response(params?: {
    body?: ContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactUpdateContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactUpdateContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateContactPost$Plain(params?: {
    body?: ContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactUpdateContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactUpdateContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateContactPost$Json$Response(params?: {
    body?: ContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactUpdateContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactUpdateContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateContactPost$Json(params?: {
    body?: ContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactUpdateContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactToggleArchiveContactPost
   */
  static readonly ApiContactToggleArchiveContactPostPath = '/api/Contact/toggle-archive-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactToggleArchiveContactPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactToggleArchiveContactPost$Plain$Response(params?: {
    isArchive?: boolean;
    body?: ContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactToggleArchiveContactPostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactToggleArchiveContactPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactToggleArchiveContactPost$Plain(params?: {
    isArchive?: boolean;
    body?: ContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactToggleArchiveContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactToggleArchiveContactPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactToggleArchiveContactPost$Json$Response(params?: {
    isArchive?: boolean;
    body?: ContactDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactToggleArchiveContactPostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactToggleArchiveContactPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactToggleArchiveContactPost$Json(params?: {
    isArchive?: boolean;
    body?: ContactDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactToggleArchiveContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactCommentsGet
   */
  static readonly ApiContactCommentsGetPath = '/api/Contact/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactCommentsGet$Plain$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<ContactCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactCommentsGetPath, 'get');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactCommentsGet$Plain(params?: {
    contactId?: number;
  }): Observable<ContactCommentDtoListBaseResponse> {

    return this.apiContactCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactCommentDtoListBaseResponse>) => r.body as ContactCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactCommentsGet$Json$Response(params?: {
    contactId?: number;
  }): Observable<StrictHttpResponse<ContactCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactCommentsGetPath, 'get');
    if (params) {
      rb.query('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactCommentsGet$Json(params?: {
    contactId?: number;
  }): Observable<ContactCommentDtoListBaseResponse> {

    return this.apiContactCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactCommentDtoListBaseResponse>) => r.body as ContactCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactAddCommentPost
   */
  static readonly ApiContactAddCommentPostPath = '/api/Contact/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddCommentPost$Plain$Response(params?: {
    body?: ContactCommentDto
  }): Observable<StrictHttpResponse<ContactCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddCommentPost$Plain(params?: {
    body?: ContactCommentDto
  }): Observable<ContactCommentDtoBaseResponse> {

    return this.apiContactAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ContactCommentDtoBaseResponse>) => r.body as ContactCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddCommentPost$Json$Response(params?: {
    body?: ContactCommentDto
  }): Observable<StrictHttpResponse<ContactCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactAddCommentPost$Json(params?: {
    body?: ContactCommentDto
  }): Observable<ContactCommentDtoBaseResponse> {

    return this.apiContactAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ContactCommentDtoBaseResponse>) => r.body as ContactCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactUpdateCommentPut
   */
  static readonly ApiContactUpdateCommentPutPath = '/api/Contact/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateCommentPut$Plain$Response(params?: {
    body?: ContactCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateCommentPut$Plain(params?: {
    body?: ContactCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateCommentPut$Json$Response(params?: {
    body?: ContactCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiContactUpdateCommentPut$Json(params?: {
    body?: ContactCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiContactUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiContactDeleteCommentDelete
   */
  static readonly ApiContactDeleteCommentDeletePath = '/api/Contact/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiContactDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiContactDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContactService.ApiContactDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiContactDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiContactDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiContactDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
