/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';

@Injectable({
  providedIn: 'root',
})
export class LiveUpdateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLiveUpdateHasUserSeenProjectUpdateGet
   */
  static readonly ApiLiveUpdateHasUserSeenProjectUpdateGetPath = '/api/LiveUpdate/has-user-seen-project-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenProjectUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenProjectUpdateGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenProjectUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenProjectUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenProjectUpdateGet$Plain(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenProjectUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenProjectUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenProjectUpdateGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenProjectUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenProjectUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenProjectUpdateGet$Json(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenProjectUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiLiveUpdateHasUserSeenInvoiceUpdateGet
   */
  static readonly ApiLiveUpdateHasUserSeenInvoiceUpdateGetPath = '/api/LiveUpdate/has-user-seen-invoice-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenInvoiceUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenInvoiceUpdateGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenInvoiceUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenInvoiceUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenInvoiceUpdateGet$Plain(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenInvoiceUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenInvoiceUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenInvoiceUpdateGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenInvoiceUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenInvoiceUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenInvoiceUpdateGet$Json(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenInvoiceUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet
   */
  static readonly ApiLiveUpdateHasUserSeenPurchaseOrderUpdateGetPath = '/api/LiveUpdate/has-user-seen-purchase-order-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenPurchaseOrderUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Plain(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveUpdateService.ApiLiveUpdateHasUserSeenPurchaseOrderUpdateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Json(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiLiveUpdateHasUserSeenPurchaseOrderUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
