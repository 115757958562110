/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Ascending
 * 
 * 1 = Descending
 */
export enum OrderDirection {
  Ascending = 0,
  Descending = 1
}
