import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanyDto, CurrencyType, MilestoneNotificationDto } from "app/api/models";
import { CompanyService, ContactService, ProjectService } from "app/api/services";
import { TimeUtil } from "app/core/utils/time-utils";

@Component({
    selector       : 'milestone-notification-dialog',
    templateUrl    : './milestone-notification-dialog.component.html',
    styles         : [`
        ::ng-deep .notification-popup-cont .mat-mdc-form-field-infix {
            min-height: 20px !important;
        }
    `]
})
export class MilestoneNotificationDialogComponent implements OnInit
{
    public milestoneForm: FormGroup;

    public milestone: MilestoneNotificationDto;
    public changingDate: boolean;

    public CurrencyType = CurrencyType;

    companies: CompanyDto[] = [];

    constructor(
        public companyService: CompanyService,
        public projectService: ProjectService,
        public contactService: ContactService,
        public dialogRef: MatDialogRef<MilestoneNotificationDialogComponent>,
        private _formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {milestone: MilestoneNotificationDto}
    )
    {}

    ngOnInit(): void
    {
        this.milestone = this.data.milestone;
        console.log("milestone", this.milestone);

        this.loadCompanies();

        this.buildForm();
    }

    buildForm() {
        this.milestoneForm = this._formBuilder.group({
          vatApplicable           : [this.milestone.vatApplicable   ?? true],
          poRef                   : [this.milestone.poRef           ?? ''],
          date                    : [this.milestone.date            ?? ''],
          paymentTerms            : [this.milestone.paymentTerms    ?? ''],
          notes                   : [this.milestone.notes           ?? ''],
          
          useAltCurrency          : [this.milestone.currencyType != CurrencyType.GBP],

          companyName             : [this.milestone.companyName     ?? '',              Validators.required],
          invoiceAddressId        : [this.milestone.invoiceAddress.projectAddressId,    Validators.required],
          mainContactEmail        : [this.milestone.mainContactEmail     ?? '',         Validators.required],
        });

        this.cdr.detectChanges();
    }

    loadCompanies() {
        this.companyService.apiCompanyGetAllGet$Json$Response().subscribe(res => {
            if(!res?.body?.data) return;
            this.companies = res.body.data;
            this.cdr.detectChanges();
        });
    }

    getAddresses() {
        this.milestoneForm.controls['date'].valid;
        if(!this.companies.length) return [];
        var name = this.milestoneForm.controls['companyName'].value;
        return name ? this.companies.find(x => x.name == name)?.invoiceAddresses : [];
    }
    
    getContacts() {
        if(!this.companies.length) return [];
        var name = this.milestoneForm.controls['companyName'].value;
        return name ? this.companies.find(x => x.name == name)?.contacts : [];
    }

    companyChanged() {
        this.milestoneForm.controls['invoiceAddressId'].setValue(null);
        this.milestoneForm.controls['mainContactEmail'].setValue('');
    }

    getEmails() {
        return this.getContacts().flatMap(x => x.emails).map(x => x.email);
    }
    
    submitMilestone() {
        console.log("valid?", this.milestoneForm.valid);
        var formVals = this.milestoneForm.value;

        this.milestone.vatApplicable = formVals.vatApplicable;
        this.milestone.poRef = formVals.poRef;
        this.milestone.paymentTerms = formVals.paymentTerms;
        this.milestone.notes = formVals.notes;

        //set company/address/contact
        this.milestone.companyName = formVals.companyName;
        this.milestone.mainContactEmail = formVals.mainContactEmail;
        this.milestone.invoiceAddress = this.getAddresses().find(x => x.projectAddressId == formVals.invoiceAddressId);
        this.milestone.invoiceAddressId = formVals.invoiceAddressId;


        if(!formVals.useAltCurrency) {
            this.milestone.currencyRate = 1;
            this.milestone.currencyType = CurrencyType.GBP;
        }

        this.dialogRef.close({milestone: this.milestone, submit: true});
    }

    getCurrencyTypeText(type: CurrencyType) {
        switch(type) {
            case CurrencyType.GBP: return '£ GBP';
            case CurrencyType.USD: return '$ USD';
            case CurrencyType.EUR: return '€ EUR';
            default: return 'N/A';
        }
    }

    GetCurrencyPipeValue = () => 
        this.milestone?.currencyType == CurrencyType.USD ? 'USD'
        :  this.milestone?.currencyType == CurrencyType.EUR ? 'EUR'
        : 'GBP';
        
    usesAlternativeCurrency = () => !!this.milestone?.currencyType && this.milestoneForm?.controls['useAltCurrency'].value;

    saveDate() {
        this.milestone.date = TimeUtil.setTo1amUTC(new Date(this.milestoneForm.value.date)).toISOString();
        
        this.dialogRef.close({milestone: this.milestone, submit: false});
    }

    close() {
        this.dialogRef.close(null);
    }
}
