/* eslint-disable */

// Updated at: 20210425 - 1792 icons
export const material = [
    '10k',
    '10mp',
    '11mp',
    '12mp',
    '13mp',
    '14mp',
    '15mp',
    '16mp',
    '17mp',
    '18mp',
    '19mp',
    '1k',
    '1k_plus',
    '1x_mobiledata',
    '20mp',
    '21mp',
    '22mp',
    '23mp',
    '24mp',
    '2k',
    '2k_plus',
    '2mp',
    '30fps',
    '30fps_select',
    '360',
    '3d_rotation',
    '3g_mobiledata',
    '3k',
    '3k_plus',
    '3mp',
    '3p',
    '4g_mobiledata',
    '4g_plus_mobiledata',
    '4k',
    '4k_plus',
    '4mp',
    '5g',
    '5k',
    '5k_plus',
    '5mp',
    '60fps',
    '60fps_select',
    '6_ft_apart',
    '6k',
    '6k_plus',
    '6mp',
    '7k',
    '7k_plus',
    '7mp',
    '8k',
    '8k_plus',
    '8mp',
    '9k',
    '9k_plus',
    '9mp',
    'ac_unit',
    'access_alarm',
    'access_alarms',
    'access_time',
    'access_time_filled',
    'accessibility',
    'accessibility_new',
    'accessible',
    'accessible_forward',
    'account_balance',
    'account_balance_wallet',
    'account_box',
    'account_circle',
    'account_tree',
    'ad_units',
    'adb',
    'add',
    'add_a_photo',
    'add_alarm',
    'add_alert',
    'add_box',
    'add_business',
    'add_chart',
    'add_circle',
    'add_circle_outline',
    'add_comment',
    'add_ic_call',
    'add_link',
    'add_location',
    'add_location_alt',
    'add_moderator',
    'add_photo_alternate',
    'add_reaction',
    'add_road',
    'add_shopping_cart',
    'add_task',
    'add_to_drive',
    'add_to_home_screen',
    'add_to_photos',
    'add_to_queue',
    'addchart',
    'adjust',
    'admin_panel_settings',
    'ads_click',
    'agriculture',
    'air',
    'airline_seat_flat',
    'airline_seat_flat_angled',
    'airline_seat_individual_suite',
    'airline_seat_legroom_extra',
    'airline_seat_legroom_normal',
    'airline_seat_legroom_reduced',
    'airline_seat_recline_extra',
    'airline_seat_recline_normal',
    'airplane_ticket',
    'airplanemode_active',
    'airplanemode_inactive',
    'airplay',
    'airport_shuttle',
    'alarm',
    'alarm_add',
    'alarm_off',
    'alarm_on',
    'album',
    'align_horizontal_center',
    'align_horizontal_left',
    'align_horizontal_right',
    'align_vertical_bottom',
    'align_vertical_center',
    'align_vertical_top',
    'all_inbox',
    'all_inclusive',
    'all_out',
    'alt_route',
    'alternate_email',
    'analytics',
    'anchor',
    'android',
    'animation',
    'announcement',
    'aod',
    'apartment',
    'api',
    'app_blocking',
    'app_registration',
    'app_settings_alt',
    'approval',
    'apps',
    'architecture',
    'archive',
    'area_chart',
    'arrow_back',
    'arrow_back_ios',
    'arrow_back_ios_new',
    'arrow_circle_down',
    'arrow_circle_up',
    'arrow_downward',
    'arrow_drop_down',
    'arrow_drop_down_circle',
    'arrow_drop_up',
    'arrow_forward',
    'arrow_forward_ios',
    'arrow_left',
    'arrow_right',
    'arrow_right_alt',
    'arrow_upward',
    'art_track',
    'article',
    'aspect_ratio',
    'assessment',
    'assignment',
    'assignment_ind',
    'assignment_late',
    'assignment_return',
    'assignment_returned',
    'assignment_turned_in',
    'assistant',
    'assistant_direction',
    'assistant_photo',
    'atm',
    'attach_email',
    'attach_file',
    'attach_money',
    'attachment',
    'attractions',
    'attribution',
    'audiotrack',
    'auto_awesome',
    'auto_awesome_mosaic',
    'auto_awesome_motion',
    'auto_delete',
    'auto_fix_high',
    'auto_fix_normal',
    'auto_fix_off',
    'auto_graph',
    'auto_stories',
    'autofps_select',
    'autorenew',
    'av_timer',
    'baby_changing_station',
    'back_hand',
    'backpack',
    'backspace',
    'backup',
    'backup_table',
    'badge',
    'bakery_dining',
    'balcony',
    'ballot',
    'bar_chart',
    'batch_prediction',
    'bathroom',
    'bathtub',
    'battery_alert',
    'battery_charging_full',
    'battery_full',
    'battery_saver',
    'battery_std',
    'battery_unknown',
    'beach_access',
    'bed',
    'bedroom_baby',
    'bedroom_child',
    'bedroom_parent',
    'bedtime',
    'beenhere',
    'bento',
    'bike_scooter',
    'biotech',
    'blender',
    'block',
    'bloodtype',
    'bluetooth',
    'bluetooth_audio',
    'bluetooth_connected',
    'bluetooth_disabled',
    'bluetooth_drive',
    'bluetooth_searching',
    'blur_circular',
    'blur_linear',
    'blur_off',
    'blur_on',
    'bolt',
    'book',
    'book_online',
    'bookmark',
    'bookmark_add',
    'bookmark_added',
    'bookmark_border',
    'bookmark_remove',
    'bookmarks',
    'border_all',
    'border_bottom',
    'border_clear',
    'border_color',
    'border_horizontal',
    'border_inner',
    'border_left',
    'border_outer',
    'border_right',
    'border_style',
    'border_top',
    'border_vertical',
    'branding_watermark',
    'breakfast_dining',
    'brightness_1',
    'brightness_2',
    'brightness_3',
    'brightness_4',
    'brightness_5',
    'brightness_6',
    'brightness_7',
    'brightness_auto',
    'brightness_high',
    'brightness_low',
    'brightness_medium',
    'broken_image',
    'browser_not_supported',
    'brunch_dining',
    'brush',
    'bubble_chart',
    'bug_report',
    'build',
    'build_circle',
    'bungalow',
    'burst_mode',
    'bus_alert',
    'business',
    'business_center',
    'cabin',
    'cable',
    'cached',
    'cake',
    'calculate',
    'calendar_today',
    'calendar_view_day',
    'calendar_view_month',
    'calendar_view_week',
    'call',
    'call_end',
    'call_made',
    'call_merge',
    'call_missed',
    'call_missed_outgoing',
    'call_received',
    'call_split',
    'call_to_action',
    'camera',
    'camera_alt',
    'camera_enhance',
    'camera_front',
    'camera_indoor',
    'camera_outdoor',
    'camera_rear',
    'camera_roll',
    'cameraswitch',
    'campaign',
    'cancel',
    'cancel_presentation',
    'cancel_schedule_send',
    'car_rental',
    'car_repair',
    'card_giftcard',
    'card_membership',
    'card_travel',
    'carpenter',
    'cases',
    'casino',
    'cast',
    'cast_connected',
    'cast_for_education',
    'catching_pokemon',
    'category',
    'celebration',
    'cell_wifi',
    'center_focus_strong',
    'center_focus_weak',
    'chair',
    'chair_alt',
    'chalet',
    'change_circle',
    'change_history',
    'charging_station',
    'chat',
    'chat_bubble',
    'chat_bubble_outline',
    'check',
    'check_box',
    'check_box_outline_blank',
    'check_circle',
    'check_circle_outline',
    'checklist',
    'checklist_rtl',
    'checkroom',
    'chevron_left',
    'chevron_right',
    'child_care',
    'child_friendly',
    'chrome_reader_mode',
    'circle',
    'circle_notifications',
    'class',
    'clean_hands',
    'cleaning_services',
    'clear',
    'clear_all',
    'close',
    'close_fullscreen',
    'closed_caption',
    'closed_caption_disabled',
    'closed_caption_off',
    'cloud',
    'cloud_circle',
    'cloud_done',
    'cloud_download',
    'cloud_off',
    'cloud_queue',
    'cloud_upload',
    'code',
    'code_off',
    'coffee',
    'coffee_maker',
    'collections',
    'collections_bookmark',
    'color_lens',
    'colorize',
    'comment',
    'comment_bank',
    'commute',
    'compare',
    'compare_arrows',
    'compass_calibration',
    'compost',
    'compress',
    'computer',
    'confirmation_number',
    'connect_without_contact',
    'connected_tv',
    'construction',
    'contact_mail',
    'contact_page',
    'contact_phone',
    'contact_support',
    'contactless',
    'contacts',
    'content_copy',
    'content_cut',
    'content_paste',
    'content_paste_off',
    'control_camera',
    'control_point',
    'control_point_duplicate',
    'copy_all',
    'copyright',
    'coronavirus',
    'corporate_fare',
    'cottage',
    'countertops',
    'create',
    'create_new_folder',
    'credit_card',
    'credit_card_off',
    'credit_score',
    'crib',
    'crop',
    'crop_16_9',
    'crop_3_2',
    'crop_5_4',
    'crop_7_5',
    'crop_din',
    'crop_free',
    'crop_landscape',
    'crop_original',
    'crop_portrait',
    'crop_rotate',
    'crop_square',
    'cruelty_free',
    'dangerous',
    'dark_mode',
    'dashboard',
    'dashboard_customize',
    'data_exploration',
    'data_saver_off',
    'data_saver_on',
    'data_usage',
    'date_range',
    'deck',
    'dehaze',
    'delete',
    'delete_forever',
    'delete_outline',
    'delete_sweep',
    'delivery_dining',
    'departure_board',
    'description',
    'design_services',
    'desktop_access_disabled',
    'desktop_mac',
    'desktop_windows',
    'details',
    'developer_board',
    'developer_board_off',
    'developer_mode',
    'device_hub',
    'device_thermostat',
    'device_unknown',
    'devices',
    'devices_other',
    'dialer_sip',
    'dialpad',
    'dining',
    'dinner_dining',
    'directions',
    'directions_bike',
    'directions_boat',
    'directions_boat_filled',
    'directions_bus',
    'directions_bus_filled',
    'directions_car',
    'directions_car_filled',
    'directions_off',
    'directions_railway',
    'directions_railway_filled',
    'directions_run',
    'directions_subway',
    'directions_subway_filled',
    'directions_transit',
    'directions_transit_filled',
    'directions_walk',
    'dirty_lens',
    'disabled_by_default',
    'disabled_visible',
    'disc_full',
    'dns',
    'do_disturb',
    'do_disturb_alt',
    'do_disturb_off',
    'do_disturb_on',
    'do_not_disturb',
    'do_not_disturb_alt',
    'do_not_disturb_off',
    'do_not_disturb_on',
    'do_not_disturb_on_total_silence',
    'do_not_step',
    'do_not_touch',
    'dock',
    'document_scanner',
    'domain',
    'domain_disabled',
    'domain_verification',
    'done',
    'done_all',
    'done_outline',
    'donut_large',
    'donut_small',
    'door_back',
    'door_front',
    'door_sliding',
    'doorbell',
    'double_arrow',
    'downhill_skiing',
    'download',
    'download_done',
    'download_for_offline',
    'downloading',
    'drafts',
    'drag_handle',
    'drag_indicator',
    'draw',
    'drive_eta',
    'drive_file_move',
    'drive_file_move_rtl',
    'drive_file_rename_outline',
    'drive_folder_upload',
    'dry',
    'dry_cleaning',
    'duo',
    'dvr',
    'dynamic_feed',
    'dynamic_form',
    'e_mobiledata',
    'earbuds',
    'earbuds_battery',
    'east',
    'edgesensor_high',
    'edgesensor_low',
    'edit',
    'edit_attributes',
    'edit_calendar',
    'edit_location',
    'edit_location_alt',
    'edit_note',
    'edit_notifications',
    'edit_off',
    'edit_road',
    'eject',
    'elderly',
    'electric_bike',
    'electric_car',
    'electric_moped',
    'electric_rickshaw',
    'electric_scooter',
    'electrical_services',
    'elevator',
    'email',
    'emergency',
    'emoji_emotions',
    'emoji_events',
    'emoji_flags',
    'emoji_food_beverage',
    'emoji_nature',
    'emoji_objects',
    'emoji_people',
    'emoji_symbols',
    'emoji_transportation',
    'engineering',
    'enhanced_encryption',
    'equalizer',
    'error',
    'error_outline',
    'escalator',
    'escalator_warning',
    'euro',
    'euro_symbol',
    'ev_station',
    'event',
    'event_available',
    'event_busy',
    'event_note',
    'event_seat',
    'exit_to_app',
    'expand',
    'expand_less',
    'expand_more',
    'explicit',
    'explore',
    'explore_off',
    'exposure',
    'exposure_neg_1',
    'exposure_neg_2',
    'exposure_plus_1',
    'exposure_plus_2',
    'exposure_zero',
    'extension',
    'extension_off',
    'face',
    'face_retouching_natural',
    'face_retouching_off',
    'facebook',
    'fact_check',
    'family_restroom',
    'fast_forward',
    'fast_rewind',
    'fastfood',
    'favorite',
    'favorite_border',
    'featured_play_list',
    'featured_video',
    'feed',
    'feedback',
    'female',
    'fence',
    'festival',
    'fiber_dvr',
    'fiber_manual_record',
    'fiber_new',
    'fiber_pin',
    'fiber_smart_record',
    'file_copy',
    'file_download',
    'file_download_done',
    'file_download_off',
    'file_present',
    'file_upload',
    'filter',
    'filter_1',
    'filter_2',
    'filter_3',
    'filter_4',
    'filter_5',
    'filter_6',
    'filter_7',
    'filter_8',
    'filter_9',
    'filter_9_plus',
    'filter_alt',
    'filter_b_and_w',
    'filter_center_focus',
    'filter_drama',
    'filter_frames',
    'filter_hdr',
    'filter_list',
    'filter_none',
    'filter_tilt_shift',
    'filter_vintage',
    'find_in_page',
    'find_replace',
    'fingerprint',
    'fire_extinguisher',
    'fireplace',
    'first_page',
    'fit_screen',
    'fitness_center',
    'flag',
    'flaky',
    'flare',
    'flash_auto',
    'flash_off',
    'flash_on',
    'flashlight_off',
    'flashlight_on',
    'flatware',
    'flight',
    'flight_land',
    'flight_takeoff',
    'flip',
    'flip_camera_android',
    'flip_camera_ios',
    'flip_to_back',
    'flip_to_front',
    'flourescent',
    'flutter_dash',
    'fmd_bad',
    'fmd_good',
    'folder',
    'folder_open',
    'folder_shared',
    'folder_special',
    'follow_the_signs',
    'font_download',
    'font_download_off',
    'food_bank',
    'format_align_center',
    'format_align_justify',
    'format_align_left',
    'format_align_right',
    'format_bold',
    'format_clear',
    'format_color_fill',
    'format_color_reset',
    'format_color_text',
    'format_indent_decrease',
    'format_indent_increase',
    'format_italic',
    'format_line_spacing',
    'format_list_bulleted',
    'format_list_numbered',
    'format_list_numbered_rtl',
    'format_paint',
    'format_quote',
    'format_shapes',
    'format_size',
    'format_strikethrough',
    'format_textdirection_l_to_r',
    'format_textdirection_r_to_l',
    'format_underlined',
    'forum',
    'forward',
    'forward_10',
    'forward_30',
    'forward_5',
    'forward_to_inbox',
    'foundation',
    'free_breakfast',
    'free_cancellation',
    'front_hand',
    'fullscreen',
    'fullscreen_exit',
    'functions',
    'g_mobiledata',
    'g_translate',
    'gamepad',
    'games',
    'garage',
    'gavel',
    'generating_tokens',
    'gesture',
    'get_app',
    'gif',
    'gite',
    'golf_course',
    'gpp_bad',
    'gpp_good',
    'gpp_maybe',
    'gps_fixed',
    'gps_not_fixed',
    'gps_off',
    'grade',
    'gradient',
    'grading',
    'grain',
    'graphic_eq',
    'grass',
    'grid_3x3',
    'grid_4x4',
    'grid_goldenratio',
    'grid_off',
    'grid_on',
    'grid_view',
    'group',
    'group_add',
    'group_off',
    'group_work',
    'groups',
    'h_mobiledata',
    'h_plus_mobiledata',
    'hail',
    'handyman',
    'hardware',
    'hd',
    'hdr_auto',
    'hdr_auto_select',
    'hdr_enhanced_select',
    'hdr_off',
    'hdr_off_select',
    'hdr_on',
    'hdr_on_select',
    'hdr_plus',
    'hdr_strong',
    'hdr_weak',
    'headphones',
    'headphones_battery',
    'headset',
    'headset_mic',
    'headset_off',
    'healing',
    'health_and_safety',
    'hearing',
    'hearing_disabled',
    'height',
    'help',
    'help_center',
    'help_outline',
    'hevc',
    'hide_image',
    'hide_source',
    'high_quality',
    'highlight',
    'highlight_alt',
    'highlight_off',
    'hiking',
    'history',
    'history_edu',
    'history_toggle_off',
    'holiday_village',
    'home',
    'home_max',
    'home_mini',
    'home_repair_service',
    'home_work',
    'horizontal_distribute',
    'horizontal_rule',
    'horizontal_split',
    'hot_tub',
    'hotel',
    'hotel_class',
    'hourglass_bottom',
    'hourglass_disabled',
    'hourglass_empty',
    'hourglass_full',
    'hourglass_top',
    'house',
    'house_siding',
    'houseboat',
    'how_to_reg',
    'how_to_vote',
    'http',
    'https',
    'hvac',
    'ice_skating',
    'icecream',
    'image',
    'image_aspect_ratio',
    'image_not_supported',
    'image_search',
    'imagesearch_roller',
    'import_contacts',
    'import_export',
    'important_devices',
    'inbox',
    'incomplete_circle',
    'indeterminate_check_box',
    'info',
    'input',
    'insert_chart',
    'insert_chart_outlined',
    'insert_comment',
    'insert_drive_file',
    'insert_emoticon',
    'insert_invitation',
    'insert_link',
    'insert_photo',
    'insights',
    'integration_instructions',
    'inventory',
    'inventory_2',
    'invert_colors',
    'invert_colors_off',
    'ios_share',
    'iron',
    'iso',
    'kayaking',
    'keyboard',
    'keyboard_alt',
    'keyboard_arrow_down',
    'keyboard_arrow_left',
    'keyboard_arrow_right',
    'keyboard_arrow_up',
    'keyboard_backspace',
    'keyboard_capslock',
    'keyboard_hide',
    'keyboard_return',
    'keyboard_tab',
    'keyboard_voice',
    'king_bed',
    'kitchen',
    'kitesurfing',
    'label',
    'label_important',
    'label_off',
    'landscape',
    'language',
    'laptop',
    'laptop_chromebook',
    'laptop_mac',
    'laptop_windows',
    'last_page',
    'launch',
    'layers',
    'layers_clear',
    'leaderboard',
    'leak_add',
    'leak_remove',
    'legend_toggle',
    'lens',
    'lens_blur',
    'library_add',
    'library_add_check',
    'library_books',
    'library_music',
    'light',
    'light_mode',
    'lightbulb',
    'line_style',
    'line_weight',
    'linear_scale',
    'link',
    'link_off',
    'linked_camera',
    'liquor',
    'list',
    'list_alt',
    'live_help',
    'live_tv',
    'living',
    'local_activity',
    'local_airport',
    'local_atm',
    'local_bar',
    'local_cafe',
    'local_car_wash',
    'local_convenience_store',
    'local_dining',
    'local_drink',
    'local_fire_department',
    'local_florist',
    'local_gas_station',
    'local_grocery_store',
    'local_hospital',
    'local_hotel',
    'local_laundry_service',
    'local_library',
    'local_mall',
    'local_movies',
    'local_offer',
    'local_parking',
    'local_pharmacy',
    'local_phone',
    'local_pizza',
    'local_play',
    'local_police',
    'local_post_office',
    'local_printshop',
    'local_see',
    'local_shipping',
    'local_taxi',
    'location_city',
    'location_disabled',
    'location_off',
    'location_on',
    'location_searching',
    'lock',
    'lock_clock',
    'lock_open',
    'login',
    'logout',
    'looks',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6',
    'looks_one',
    'looks_two',
    'loop',
    'loupe',
    'low_priority',
    'loyalty',
    'lte_mobiledata',
    'lte_plus_mobiledata',
    'luggage',
    'lunch_dining',
    'mail',
    'mail_outline',
    'male',
    'manage_accounts',
    'manage_search',
    'map',
    'maps_home_work',
    'maps_ugc',
    'margin',
    'mark_as_unread',
    'mark_chat_read',
    'mark_chat_unread',
    'mark_email_read',
    'mark_email_unread',
    'markunread',
    'markunread_mailbox',
    'masks',
    'maximize',
    'media_bluetooth_off',
    'media_bluetooth_on',
    'mediation',
    'medical_services',
    'medication',
    'meeting_room',
    'memory',
    'menu',
    'menu_book',
    'menu_open',
    'merge_type',
    'message',
    'mic',
    'mic_external_off',
    'mic_external_on',
    'mic_none',
    'mic_off',
    'microwave',
    'military_tech',
    'minimize',
    'miscellaneous_services',
    'missed_video_call',
    'mms',
    'mobile_friendly',
    'mobile_off',
    'mobile_screen_share',
    'mobiledata_off',
    'mode',
    'mode_comment',
    'mode_edit',
    'mode_edit_outline',
    'mode_night',
    'mode_standby',
    'model_training',
    'monetization_on',
    'money',
    'money_off',
    'money_off_csred',
    'monitor',
    'monitor_weight',
    'monochrome_photos',
    'mood',
    'mood_bad',
    'moped',
    'more',
    'more_horiz',
    'more_time',
    'more_vert',
    'motion_photos_auto',
    'motion_photos_off',
    'motion_photos_on',
    'motion_photos_pause',
    'motion_photos_paused',
    'mouse',
    'move_to_inbox',
    'movie',
    'movie_creation',
    'movie_filter',
    'moving',
    'mp',
    'multiline_chart',
    'multiple_stop',
    'museum',
    'music_note',
    'music_off',
    'music_video',
    'my_location',
    'nat',
    'nature',
    'nature_people',
    'navigate_before',
    'navigate_next',
    'navigation',
    'near_me',
    'near_me_disabled',
    'nearby_error',
    'nearby_off',
    'network_cell',
    'network_check',
    'network_locked',
    'network_wifi',
    'new_label',
    'new_releases',
    'next_plan',
    'next_week',
    'nfc',
    'night_shelter',
    'nightlife',
    'nightlight',
    'nightlight_round',
    'nights_stay',
    'no_accounts',
    'no_backpack',
    'no_cell',
    'no_drinks',
    'no_encryption',
    'no_encryption_gmailerrorred',
    'no_flash',
    'no_food',
    'no_luggage',
    'no_meals',
    'no_meeting_room',
    'no_photography',
    'no_sim',
    'no_stroller',
    'no_transfer',
    'nordic_walking',
    'north',
    'north_east',
    'north_west',
    'not_accessible',
    'not_interested',
    'not_listed_location',
    'not_started',
    'note',
    'note_add',
    'note_alt',
    'notes',
    'notification_add',
    'notification_important',
    'notifications',
    'notifications_active',
    'notifications_none',
    'notifications_off',
    'notifications_paused',
    'offline_bolt',
    'offline_pin',
    'offline_share',
    'ondemand_video',
    'online_prediction',
    'opacity',
    'open_in_browser',
    'open_in_full',
    'open_in_new',
    'open_in_new_off',
    'open_with',
    'other_houses',
    'outbound',
    'outbox',
    'outdoor_grill',
    'outlet',
    'outlined_flag',
    'padding',
    'pages',
    'pageview',
    'paid',
    'palette',
    'pan_tool',
    'panorama',
    'panorama_fish_eye',
    'panorama_horizontal',
    'panorama_horizontal_select',
    'panorama_photosphere',
    'panorama_photosphere_select',
    'panorama_vertical',
    'panorama_vertical_select',
    'panorama_wide_angle',
    'panorama_wide_angle_select',
    'paragliding',
    'park',
    'party_mode',
    'password',
    'pattern',
    'pause',
    'pause_circle',
    'pause_circle_filled',
    'pause_circle_outline',
    'pause_presentation',
    'payment',
    'payments',
    'pedal_bike',
    'pending',
    'pending_actions',
    'people',
    'people_alt',
    'people_outline',
    'perm_camera_mic',
    'perm_contact_calendar',
    'perm_data_setting',
    'perm_device_information',
    'perm_identity',
    'perm_media',
    'perm_phone_msg',
    'perm_scan_wifi',
    'person',
    'person_add',
    'person_add_alt',
    'person_add_alt_1',
    'person_add_disabled',
    'person_off',
    'person_outline',
    'person_pin',
    'person_pin_circle',
    'person_remove',
    'person_remove_alt_1',
    'person_search',
    'personal_injury',
    'personal_video',
    'pest_control',
    'pest_control_rodent',
    'pets',
    'phone',
    'phone_android',
    'phone_bluetooth_speaker',
    'phone_callback',
    'phone_disabled',
    'phone_enabled',
    'phone_forwarded',
    'phone_in_talk',
    'phone_iphone',
    'phone_locked',
    'phone_missed',
    'phone_paused',
    'phonelink',
    'phonelink_erase',
    'phonelink_lock',
    'phonelink_off',
    'phonelink_ring',
    'phonelink_setup',
    'photo',
    'photo_album',
    'photo_camera',
    'photo_camera_back',
    'photo_camera_front',
    'photo_filter',
    'photo_library',
    'photo_size_select_actual',
    'photo_size_select_large',
    'photo_size_select_small',
    'piano',
    'piano_off',
    'picture_as_pdf',
    'picture_in_picture',
    'picture_in_picture_alt',
    'pie_chart',
    'pie_chart_outline',
    'pin',
    'pin_drop',
    'pin_end',
    'pin_invoke',
    'pivot_table_chart',
    'place',
    'plagiarism',
    'play_arrow',
    'play_circle',
    'play_circle_filled',
    'play_circle_outline',
    'play_disabled',
    'play_for_work',
    'play_lesson',
    'playlist_add',
    'playlist_add_check',
    'playlist_play',
    'plumbing',
    'plus_one',
    'podcasts',
    'point_of_sale',
    'policy',
    'poll',
    'polymer',
    'pool',
    'portable_wifi_off',
    'portrait',
    'post_add',
    'power',
    'power_input',
    'power_off',
    'power_settings_new',
    'precision_manufacturing',
    'pregnant_woman',
    'present_to_all',
    'preview',
    'price_change',
    'price_check',
    'print',
    'print_disabled',
    'priority_high',
    'privacy_tip',
    'private_connectivity',
    'production_quantity_limits',
    'psychology',
    'public',
    'public_off',
    'publish',
    'published_with_changes',
    'push_pin',
    'qr_code',
    'qr_code_2',
    'qr_code_scanner',
    'query_builder',
    'query_stats',
    'question_answer',
    'queue',
    'queue_music',
    'queue_play_next',
    'quickreply',
    'quiz',
    'r_mobiledata',
    'radar',
    'radio',
    'radio_button_checked',
    'radio_button_unchecked',
    'railway_alert',
    'ramen_dining',
    'rate_review',
    'raw_off',
    'raw_on',
    'read_more',
    'real_estate_agent',
    'receipt',
    'receipt_long',
    'recent_actors',
    'recommend',
    'record_voice_over',
    'recycling',
    'redeem',
    'redo',
    'reduce_capacity',
    'refresh',
    'remember_me',
    'remove',
    'remove_circle',
    'remove_circle_outline',
    'remove_done',
    'remove_from_queue',
    'remove_moderator',
    'remove_red_eye',
    'remove_shopping_cart',
    'reorder',
    'repeat',
    'repeat_on',
    'repeat_one',
    'repeat_one_on',
    'replay',
    'replay_10',
    'replay_30',
    'replay_5',
    'replay_circle_filled',
    'reply',
    'reply_all',
    'report',
    'report_gmailerrorred',
    'report_off',
    'report_problem',
    'request_page',
    'request_quote',
    'reset_tv',
    'restart_alt',
    'restaurant',
    'restaurant_menu',
    'restore',
    'restore_from_trash',
    'restore_page',
    'reviews',
    'rice_bowl',
    'ring_volume',
    'roofing',
    'room',
    'room_preferences',
    'room_service',
    'rotate_90_degrees_ccw',
    'rotate_left',
    'rotate_right',
    'rounded_corner',
    'router',
    'rowing',
    'rss_feed',
    'rsvp',
    'rtt',
    'rule',
    'rule_folder',
    'run_circle',
    'running_with_errors',
    'rv_hookup',
    'safety_divider',
    'sailing',
    'sanitizer',
    'satellite',
    'save',
    'save_alt',
    'saved_search',
    'savings',
    'scanner',
    'scatter_plot',
    'schedule',
    'schedule_send',
    'schema',
    'school',
    'science',
    'score',
    'screen_lock_landscape',
    'screen_lock_portrait',
    'screen_lock_rotation',
    'screen_rotation',
    'screen_search_desktop',
    'screen_share',
    'screenshot',
    'sd',
    'sd_card',
    'sd_card_alert',
    'sd_storage',
    'search',
    'search_off',
    'security',
    'security_update',
    'security_update_good',
    'security_update_warning',
    'segment',
    'select_all',
    'self_improvement',
    'sell',
    'send',
    'send_and_archive',
    'send_to_mobile',
    'sensor_door',
    'sensor_window',
    'sensors',
    'sensors_off',
    'sentiment_dissatisfied',
    'sentiment_neutral',
    'sentiment_satisfied',
    'sentiment_satisfied_alt',
    'sentiment_very_dissatisfied',
    'sentiment_very_satisfied',
    'set_meal',
    'settings',
    'settings_accessibility',
    'settings_applications',
    'settings_backup_restore',
    'settings_bluetooth',
    'settings_brightness',
    'settings_cell',
    'settings_ethernet',
    'settings_input_antenna',
    'settings_input_component',
    'settings_input_composite',
    'settings_input_hdmi',
    'settings_input_svideo',
    'settings_overscan',
    'settings_phone',
    'settings_power',
    'settings_remote',
    'settings_suggest',
    'settings_system_daydream',
    'settings_voice',
    'share',
    'share_location',
    'shield',
    'shop',
    'shop_2',
    'shop_two',
    'shopping_bag',
    'shopping_basket',
    'shopping_cart',
    'short_text',
    'shortcut',
    'show_chart',
    'shower',
    'shuffle',
    'shuffle_on',
    'shutter_speed',
    'sick',
    'signal_cellular_0_bar',
    'signal_cellular_4_bar',
    'signal_cellular_alt',
    'signal_cellular_connected_no_internet_0_bar',
    'signal_cellular_connected_no_internet_4_bar',
    'signal_cellular_no_sim',
    'signal_cellular_nodata',
    'signal_cellular_null',
    'signal_cellular_off',
    'signal_wifi_0_bar',
    'signal_wifi_4_bar',
    'signal_wifi_4_bar_lock',
    'signal_wifi_bad',
    'signal_wifi_connected_no_internet_4',
    'signal_wifi_off',
    'signal_wifi_statusbar_4_bar',
    'signal_wifi_statusbar_connected_no_internet_4',
    'signal_wifi_statusbar_null',
    'sim_card',
    'sim_card_alert',
    'sim_card_download',
    'single_bed',
    'sip',
    'skateboarding',
    'skip_next',
    'skip_previous',
    'sledding',
    'slideshow',
    'slow_motion_video',
    'smart_button',
    'smart_display',
    'smart_screen',
    'smart_toy',
    'smartphone',
    'smoke_free',
    'smoking_rooms',
    'sms',
    'sms_failed',
    'snippet_folder',
    'snooze',
    'snowboarding',
    'snowmobile',
    'snowshoeing',
    'soap',
    'social_distance',
    'sort',
    'sort_by_alpha',
    'source',
    'south',
    'south_east',
    'south_west',
    'spa',
    'space_bar',
    'space_dashboard',
    'speaker',
    'speaker_group',
    'speaker_notes',
    'speaker_notes_off',
    'speaker_phone',
    'speed',
    'spellcheck',
    'splitscreen',
    'sports',
    'sports_bar',
    'sports_baseball',
    'sports_basketball',
    'sports_cricket',
    'sports_esports',
    'sports_football',
    'sports_golf',
    'sports_handball',
    'sports_hockey',
    'sports_kabaddi',
    'sports_mma',
    'sports_motorsports',
    'sports_rugby',
    'sports_score',
    'sports_soccer',
    'sports_tennis',
    'sports_volleyball',
    'square_foot',
    'stacked_bar_chart',
    'stacked_line_chart',
    'stairs',
    'star',
    'star_border',
    'star_border_purple500',
    'star_half',
    'star_outline',
    'star_purple500',
    'star_rate',
    'stars',
    'stay_current_landscape',
    'stay_current_portrait',
    'stay_primary_landscape',
    'stay_primary_portrait',
    'sticky_note_2',
    'stop',
    'stop_circle',
    'stop_screen_share',
    'storage',
    'store',
    'store_mall_directory',
    'storefront',
    'storm',
    'straighten',
    'stream',
    'streetview',
    'strikethrough_s',
    'stroller',
    'style',
    'subdirectory_arrow_left',
    'subdirectory_arrow_right',
    'subject',
    'subscript',
    'subscriptions',
    'subtitles',
    'subtitles_off',
    'subway',
    'summarize',
    'superscript',
    'supervised_user_circle',
    'supervisor_account',
    'support',
    'support_agent',
    'surfing',
    'surround_sound',
    'swap_calls',
    'swap_horiz',
    'swap_horizontal_circle',
    'swap_vert',
    'swap_vertical_circle',
    'swipe',
    'switch_account',
    'switch_camera',
    'switch_left',
    'switch_right',
    'switch_video',
    'sync',
    'sync_alt',
    'sync_disabled',
    'sync_problem',
    'system_security_update',
    'system_security_update_good',
    'system_security_update_warning',
    'system_update',
    'system_update_alt',
    'tab',
    'tab_unselected',
    'table_chart',
    'table_rows',
    'table_view',
    'tablet',
    'tablet_android',
    'tablet_mac',
    'tag',
    'tag_faces',
    'takeout_dining',
    'tap_and_play',
    'tapas',
    'task',
    'task_alt',
    'taxi_alert',
    'terrain',
    'text_fields',
    'text_format',
    'text_rotate_up',
    'text_rotate_vertical',
    'text_rotation_angledown',
    'text_rotation_angleup',
    'text_rotation_down',
    'text_rotation_none',
    'text_snippet',
    'textsms',
    'texture',
    'theater_comedy',
    'theaters',
    'thermostat',
    'thermostat_auto',
    'thumb_down',
    'thumb_down_alt',
    'thumb_down_off_alt',
    'thumb_up',
    'thumb_up_alt',
    'thumb_up_off_alt',
    'thumbs_up_down',
    'time_to_leave',
    'timelapse',
    'timeline',
    'timer',
    'timer_10',
    'timer_10_select',
    'timer_3',
    'timer_3_select',
    'timer_off',
    'tips_and_updates',
    'title',
    'toc',
    'today',
    'toggle_off',
    'toggle_on',
    'toll',
    'tonality',
    'topic',
    'touch_app',
    'tour',
    'toys',
    'track_changes',
    'traffic',
    'train',
    'tram',
    'transfer_within_a_station',
    'transform',
    'transgender',
    'transit_enterexit',
    'translate',
    'travel_explore',
    'trending_down',
    'trending_flat',
    'trending_up',
    'trip_origin',
    'try',
    'tty',
    'tune',
    'tungsten',
    'turned_in',
    'turned_in_not',
    'tv',
    'tv_off',
    'two_wheeler',
    'umbrella',
    'unarchive',
    'undo',
    'unfold_less',
    'unfold_more',
    'unpublished',
    'unsubscribe',
    'upcoming',
    'update',
    'update_disabled',
    'upgrade',
    'upload',
    'upload_file',
    'usb',
    'usb_off',
    'verified',
    'verified_user',
    'vertical_align_bottom',
    'vertical_align_center',
    'vertical_align_top',
    'vertical_distribute',
    'vertical_split',
    'vibration',
    'video_call',
    'video_camera_back',
    'video_camera_front',
    'video_label',
    'video_library',
    'video_settings',
    'video_stable',
    'videocam',
    'videocam_off',
    'videogame_asset',
    'videogame_asset_off',
    'view_agenda',
    'view_array',
    'view_carousel',
    'view_column',
    'view_comfy',
    'view_compact',
    'view_day',
    'view_headline',
    'view_in_ar',
    'view_list',
    'view_module',
    'view_quilt',
    'view_sidebar',
    'view_stream',
    'view_week',
    'vignette',
    'villa',
    'visibility',
    'visibility_off',
    'voice_chat',
    'voice_over_off',
    'voicemail',
    'volume_down',
    'volume_mute',
    'volume_off',
    'volume_up',
    'volunteer_activism',
    'vpn_key',
    'vpn_lock',
    'vrpano',
    'wallpaper',
    'warning',
    'warning_amber',
    'wash',
    'watch',
    'watch_later',
    'water',
    'water_damage',
    'water_drop',
    'waterfall_chart',
    'waves',
    'waving_hand',
    'wb_auto',
    'wb_cloudy',
    'wb_incandescent',
    'wb_iridescent',
    'wb_shade',
    'wb_sunny',
    'wb_twilight',
    'wc',
    'web',
    'web_asset',
    'web_asset_off',
    'weekend',
    'west',
    'whatshot',
    'wheelchair_pickup',
    'where_to_vote',
    'widgets',
    'wifi',
    'wifi_calling',
    'wifi_calling_3',
    'wifi_lock',
    'wifi_off',
    'wifi_protected_setup',
    'wifi_tethering',
    'wifi_tethering_error_rounded',
    'wifi_tethering_off',
    'window',
    'wine_bar',
    'work',
    'work_off',
    'work_outline',
    'workspaces',
    'wrap_text',
    'wrong_location',
    'wysiwyg',
    'yard',
    'youtube_searched_for',
    'zoom_in',
    'zoom_out',
    'zoom_out_map',
];
export const feather = [
    'activity',
    'airplay',
    'alert-circle',
    'alert-octagon',
    'alert-triangle',
    'align-center',
    'align-justify',
    'align-left',
    'align-right',
    'anchor',
    'aperture',
    'archive',
    'arrow-down-circle',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-down',
    'arrow-left-circle',
    'arrow-left',
    'arrow-right-circle',
    'arrow-right',
    'arrow-up-circle',
    'arrow-up-left',
    'arrow-up-right',
    'arrow-up',
    'at-sign',
    'award',
    'bar-chart-2',
    'bar-chart',
    'battery-charging',
    'battery',
    'bell-off',
    'bell',
    'bluetooth',
    'bold',
    'book-open',
    'book',
    'bookmark',
    'box',
    'briefcase',
    'calendar',
    'camera-off',
    'camera',
    'cast',
    'check-circle',
    'check-square',
    'check',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevrons-down',
    'chevrons-left',
    'chevrons-right',
    'chevrons-up',
    'chrome',
    'circle',
    'clipboard',
    'clock',
    'cloud-drizzle',
    'cloud-lightning',
    'cloud-off',
    'cloud-rain',
    'cloud-snow',
    'cloud',
    'code',
    'codepen',
    'codesandbox',
    'coffee',
    'columns',
    'command',
    'compass',
    'copy',
    'corner-down-left',
    'corner-down-right',
    'corner-left-down',
    'corner-left-up',
    'corner-right-down',
    'corner-right-up',
    'corner-up-left',
    'corner-up-right',
    'cpu',
    'credit-card',
    'crop',
    'crosshair',
    'database',
    'delete',
    'disc',
    'dollar-sign',
    'download-cloud',
    'download',
    'droplet',
    'edit-2',
    'edit-3',
    'edit',
    'external-link',
    'eye-off',
    'eye',
    'facebook',
    'fast-forward',
    'feather',
    'figma',
    'file-minus',
    'file-plus',
    'file-text',
    'file',
    'film',
    'filter',
    'flag',
    'folder-minus',
    'folder-plus',
    'folder',
    'framer',
    'frown',
    'gift',
    'git-branch',
    'git-commit',
    'git-merge',
    'git-pull-request',
    'github',
    'gitlab',
    'globe',
    'grid',
    'hard-drive',
    'hash',
    'headphones',
    'heart',
    'help-circle',
    'hexagon',
    'home',
    'image',
    'inbox',
    'info',
    'instagram',
    'italic',
    'key',
    'layers',
    'layout',
    'life-buoy',
    'link-2',
    'link',
    'linkedin',
    'list',
    'loader',
    'lock',
    'log-in',
    'log-out',
    'mail',
    'map-pin',
    'map',
    'maximize-2',
    'maximize',
    'meh',
    'menu',
    'message-circle',
    'message-square',
    'mic-off',
    'mic',
    'minimize-2',
    'minimize',
    'minus-circle',
    'minus-square',
    'minus',
    'monitor',
    'moon',
    'more-horizontal',
    'more-vertical',
    'mouse-pointer',
    'move',
    'music',
    'navigation-2',
    'navigation',
    'octagon',
    'package',
    'paperclip',
    'pause-circle',
    'pause',
    'pen-tool',
    'percent',
    'phone-call',
    'phone-forwarded',
    'phone-incoming',
    'phone-missed',
    'phone-off',
    'phone-outgoing',
    'phone',
    'pie-chart',
    'play-circle',
    'play',
    'plus-circle',
    'plus-square',
    'plus',
    'pocket',
    'power',
    'printer',
    'radio',
    'refresh-ccw',
    'refresh-cw',
    'repeat',
    'rewind',
    'rotate-ccw',
    'rotate-cw',
    'rss',
    'save',
    'scissors',
    'search',
    'send',
    'server',
    'settings',
    'share-2',
    'share',
    'shield-off',
    'shield',
    'shopping-bag',
    'shopping-cart',
    'shuffle',
    'sidebar',
    'skip-back',
    'skip-forward',
    'slack',
    'slash',
    'sliders',
    'smartphone',
    'smile',
    'speaker',
    'square',
    'star',
    'stop-circle',
    'sun',
    'sunrise',
    'sunset',
    'tablet',
    'tag',
    'target',
    'terminal',
    'thermometer',
    'thumbs-down',
    'thumbs-up',
    'toggle-left',
    'toggle-right',
    'tool',
    'trash-2',
    'trash',
    'trello',
    'trending-down',
    'trending-up',
    'triangle',
    'truck',
    'tv',
    'twitch',
    'twitter',
    'type',
    'umbrella',
    'underline',
    'unlock',
    'upload-cloud',
    'upload',
    'user-check',
    'user-minus',
    'user-plus',
    'user-x',
    'user',
    'users',
    'video-off',
    'video',
    'voicemail',
    'volume-1',
    'volume-2',
    'volume-x',
    'volume',
    'watch',
    'wifi-off',
    'wifi',
    'wind',
    'x-circle',
    'x-octagon',
    'x-square',
    'x',
    'youtube',
    'zap-off',
    'zap',
    'zoom-in',
    'zoom-out',
];
// heroicons v2.0.18 - 292 icons
export const heroicons = [
    'academic-cap',
    'archive-box-arrow-down',
    'adjustments-vertical',
    'archive-box',
    'arrow-down-circle',
    'archive-box-x-mark',
    'adjustments-horizontal',
    'arrow-down-left',
    'arrow-down-on-square',
    'arrow-down-on-square-stack',
    'arrow-down',
    'arrow-down-right',
    'arrow-left',
    'arrow-left-circle',
    'arrow-down-tray',
    'arrow-long-right',
    'arrow-long-down',
    'arrow-left-on-rectangle',
    'arrow-path',
    'arrow-long-up',
    'arrow-right-circle',
    'arrow-right-on-rectangle',
    'arrow-right',
    'arrow-small-down',
    'arrow-path-rounded-square',
    'arrow-long-left',
    'arrow-small-left',
    'arrow-trending-down',
    'arrow-small-up',
    'arrow-up-left',
    'arrow-trending-up',
    'arrow-up-circle',
    'arrow-up-on-square-stack',
    'arrow-up-on-square',
    'arrow-up-right',
    'arrow-up-tray',
    'arrow-up',
    'arrow-uturn-right',
    'arrow-uturn-up',
    'arrow-top-right-on-square',
    'arrow-uturn-down',
    'arrows-pointing-out',
    'arrow-uturn-left',
    'arrows-pointing-in',
    'arrows-up-down',
    'at-symbol',
    'backspace',
    'backward',
    'banknotes',
    'arrows-right-left',
    'bars-2',
    'bars-3-bottom-left',
    'bars-3-center-left',
    'bars-3',
    'bars-arrow-down',
    'bars-4',
    'bars-arrow-up',
    'battery-0',
    'bars-3-bottom-right',
    'battery-100',
    'bell-alert',
    'bell-slash',
    'battery-50',
    'arrow-small-right',
    'beaker',
    'bell',
    'bolt',
    'bookmark-slash',
    'book-open',
    'bookmark-square',
    'bolt-slash',
    'bookmark',
    'briefcase',
    'building-library',
    'bell-snooze',
    'building-office-2',
    'building-storefront',
    'building-office',
    'calculator',
    'cake',
    'calendar-days',
    'chart-bar-square',
    'chart-bar',
    'camera',
    'bug-ant',
    'calendar',
    'chat-bubble-bottom-center',
    'chart-pie',
    'chat-bubble-left-right',
    'chat-bubble-left-ellipsis',
    'chat-bubble-bottom-center-text',
    'check-circle',
    'check-badge',
    'chat-bubble-oval-left',
    'chat-bubble-left',
    'check',
    'chat-bubble-oval-left-ellipsis',
    'chevron-double-right',
    'chevron-down',
    'chevron-double-down',
    'chevron-double-up',
    'circle-stack',
    'chevron-up-down',
    'chevron-up',
    'clipboard-document-list',
    'chevron-double-left',
    'chevron-right',
    'chevron-left',
    'cloud-arrow-down',
    'cloud-arrow-up',
    'cloud',
    'code-bracket-square',
    'code-bracket',
    'cog-6-tooth',
    'clipboard-document',
    'clock',
    'clipboard-document-check',
    'cog-8-tooth',
    'cog',
    'command-line',
    'computer-desktop',
    'cube-transparent',
    'cpu-chip',
    'credit-card',
    'cube',
    'currency-dollar',
    'currency-bangladeshi',
    'currency-euro',
    'currency-pound',
    'currency-yen',
    'currency-rupee',
    'cursor-arrow-ripple',
    'device-phone-mobile',
    'device-tablet',
    'document-arrow-down',
    'cursor-arrow-rays',
    'document-check',
    'document-chart-bar',
    'document-duplicate',
    'document-minus',
    'clipboard',
    'document-magnifying-glass',
    'document-plus',
    'document',
    'document-text',
    'ellipsis-horizontal-circle',
    'document-arrow-up',
    'ellipsis-horizontal',
    'ellipsis-vertical',
    'eye-dropper',
    'exclamation-triangle',
    'eye-slash',
    'eye',
    'exclamation-circle',
    'envelope-open',
    'face-smile',
    'film',
    'flag',
    'folder-arrow-down',
    'envelope',
    'fire',
    'folder-minus',
    'folder-open',
    'face-frown',
    'folder-plus',
    'forward',
    'funnel',
    'gift-top',
    'folder',
    'gif',
    'globe-alt',
    'finger-print',
    'globe-asia-australia',
    'globe-europe-africa',
    'hand-raised',
    'gift',
    'home',
    'identification',
    'globe-americas',
    'hashtag',
    'inbox-arrow-down',
    'inbox-stack',
    'information-circle',
    'inbox',
    'key',
    'lifebuoy',
    'hand-thumb-down',
    'language',
    'hand-thumb-up',
    'heart',
    'home-modern',
    'light-bulb',
    'lock-closed',
    'magnifying-glass-plus',
    'magnifying-glass',
    'lock-open',
    'magnifying-glass-circle',
    'link',
    'list-bullet',
    'map',
    'map-pin',
    'megaphone',
    'magnifying-glass-minus',
    'minus-circle',
    'musical-note',
    'paint-brush',
    'newspaper',
    'no-symbol',
    'minus-small',
    'paper-airplane',
    'minus',
    'microphone',
    'moon',
    'paper-clip',
    'pause',
    'phone-arrow-up-right',
    'phone-arrow-down-left',
    'phone-x-mark',
    'phone',
    'pencil',
    'play-pause',
    'photo',
    'pencil-square',
    'play',
    'plus-small',
    'plus',
    'power',
    'play-circle',
    'presentation-chart-line',
    'pause-circle',
    'presentation-chart-bar',
    'printer',
    'question-mark-circle',
    'qr-code',
    'queue-list',
    'receipt-percent',
    'radio',
    'receipt-refund',
    'plus-circle',
    'rectangle-group',
    'puzzle-piece',
    'rocket-launch',
    'rectangle-stack',
    'server',
    'rss',
    'scale',
    'server-stack',
    'share',
    'shield-exclamation',
    'shopping-cart',
    'shopping-bag',
    'signal-slash',
    'signal',
    'scissors',
    'shield-check',
    'speaker-wave',
    'speaker-x-mark',
    'squares-plus',
    'star',
    'stop-circle',
    'sun',
    'sparkles',
    'squares-2x2',
    'square-2-stack',
    'square-3-stack-3d',
    'table-cells',
    'ticket',
    'swatch',
    'tag',
    'tv',
    'user-plus',
    'user-minus',
    'stop',
    'user',
    'truck',
    'users',
    'video-camera-slash',
    'user-circle',
    'video-camera',
    'user-group',
    'trophy',
    'viewfinder-circle',
    'variable',
    'trash',
    'view-columns',
    'wifi',
    'window',
    'wrench-screwdriver',
    'wrench',
    'x-mark',
    'wallet',
    'x-circle',
];
export const googlefonts = [
    'handshake',
    'monitoring',
    'view_timeline',
];
export const customicons = [
    'invoice-envelope',
    'tick-envelope',
    'pencilled-in',
    'verified',
    'unverified',
    'archive',
    'sort-chevron-up',
    'sort-chevron-down',
];
