import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { NoAuthGuard } from './core/services/cookie-jwt-auth/guards/noAuth.guard';
import { AuthGuard } from './core/services/cookie-jwt-auth/guards/auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'email-confirmed', loadChildren: () => import('app/views/auth/email-confirmed/email-confirmed.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/views/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/views/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/views/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/views/auth/sign-up/sign-up.routes')},
            {path: '2fa', loadChildren: () => import('app/views/auth/two-factor/two-factor.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/views/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/views/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', 
            loadChildren: () =>
                import("app/views/landing/home/home.module").then(
                (m) => m.HomeModule
            )},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // Dashboard
            {
                path: 'dashboard', loadChildren: () => import('app/views/admin/dashboard/dashboard.routes')
            },

            // Pages
            {path: 'pages', children: [
                // Projects
                {path: 'projects', 
                    loadChildren: () =>
                        import("app/views/admin/pages/projects/projects.module").then(
                        (m) => m.ProjectsModule
                    ),
                },

                {path: 'crm',
                    loadChildren: () =>
                        import("app/views/admin/apps/contacts/contacts.module").then(
                        (m) => m.ContactsModule
                    ),
                },
                
                // Workload
                {path: 'workload', 
                    loadChildren: () =>
                        import("app/views/admin/pages/workload/workload.module").then(
                        (m) => m.WorkloadModule
                    ),
                },
                
                // Calendar
                {path: 'calendar', 
                    loadChildren: () =>
                        import("app/views/admin/apps/calendar/calendar.module").then(
                        (m) => m.CalendarModule
                    ),
                },

                // Invoice
                {path: 'invoicing', 
                    loadChildren: () =>
                        import("app/views/admin/pages/invoice/invoice.module").then(
                        (m) => m.InvoiceModule
                    ),
                },

                // Purchase Orders
                {path: 'purchase-orders', 
                    loadChildren: () =>
                        import("app/views/admin/pages/purchase-orders/purchase-orders.module").then(
                        (m) => m.PurchaseOrdersModule
                    ),
                },

                // Reports
                {path: 'reporting',
                    loadChildren: () =>
                        import("app/views/admin/pages/reporting/reporting.module").then(
                        (m) => m.ReportsModule
                    ),
                },

                // Historic Data
                {path: 'historic',
                    loadChildren: () =>
                        import("app/views/admin/pages/historic/historic-projects.routes"),
                },
                
                // Settings
                {path: 'settings',
                    loadChildren: () =>
                        import("app/views/admin/pages/settings/settings.module").then(
                        (m) => m.SettingsModule
                    ),
                },
            ]},

            // 404 & Catch all
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
