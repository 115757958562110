import { ResourceDto, StaffRateDto, UserDto, UserListDto, UsersByStaffRateDto } from "app/api/models";
import { sortOnProperty, sortOnSelf } from "app/app.component";

export abstract class ProjectHelper {
    staffRates: StaffRateDto[] = [];
    usersByRole: UsersByStaffRateDto[] = [];
    allUsers: UserDto[] = [];
    allProducers: UserDto[] = [];
    costResources: ResourceDto[] = [];

    sumList = (arr: number[]) => arr?.reduce((prev, cur) => +prev + +cur, 0); //+x is a unary function to parse number, this isn't a typo

    public isUrl(url: string) {
        if(!url) return false;

        url = url.trim();

        // console.log(url, url.startsWith("http") || url.startsWith("www.") ? "is" : "isn't", "a url");
        return url.startsWith("http") || url.startsWith("www.") || ((!url.includes(' ') && url.split('.')?.length > 1));
    }

    public openLink(url: string) {
        if(!url) return;
        var link = url;

        if(!link.startsWith("http://") || !link.startsWith("https://"))
            link = `https://${link}`;

        window.open(link); //todo sanitize it??
    }

    GetRoleTitle(staffRateId: number) {
        return this.staffRates?.find(x => x.staffRateId == staffRateId)?.jobTitle ?? '';
    }

    GetRoleUsers(staffRateId: number): UserListDto[] {
        return sortOnProperty(this.usersByRole?.find(x => x.staffRateId == staffRateId)?.users ?? [], 'name');
    }
    
    GetUserName(userId: number, noneAsAny: boolean = false) {
        if(!this.usersByRole) return noneAsAny ? 'Any' : '';

        for(var role of this.usersByRole)
            for(var user of role.users)
                if(user.userId == userId) return user.name;

        for(var u of this.allUsers)
            if(u.id == userId) return `${u.firstName} ${u.lastName}`;

        return noneAsAny ? 'Any' : '';
    }

    getUserHighestRole(userId: number) {
        if(!this.usersByRole) return 5; //basic user

        for(var role of this.usersByRole)
            for(var user of role.users)
                if(user.userId == userId) return user.highestRole;

        // for(var u of this.allUsers)
        //     if(u.id == userId) return u.topRole;

        return 5;
    }

    GetUserNameFirstOnly(userId: number) {
        if(!this.usersByRole) return '';

        for(var role of this.usersByRole)
            for(var user of role.users)
                if(user.userId == userId) return user.firstName;

        for(var u of this.allUsers)
            if(u.id == userId) return u.firstName;

        return '';
    }

    GetResourceName(resourceId: number) {
        var resource = this.costResources?.find(x => x.resourceId == resourceId);
        if(!resource) return '';
        
        return resource?.name || `${resource?.firstName} ${resource?.lastName}`;
    }
    
    GetResourceJobTitles(resourceId: number) {
        return sortOnSelf(this.costResources?.find(x => x.resourceId == resourceId)?.jobTitles ?? []);
    }
}
