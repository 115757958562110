/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CalendarEventBaseResponse } from '../models/calendar-event-base-response';
import { CalendarEventFull } from '../models/calendar-event-full';
import { CalendarEventsResponseBaseResponse } from '../models/calendar-events-response-base-response';
import { CombinedCalendarRequestDto } from '../models/combined-calendar-request-dto';
import { MessagesResponseBaseResponse } from '../models/messages-response-base-response';
import { OutlookUserListBaseResponse } from '../models/outlook-user-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class OutlookService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiOutlookMessagesLatestNGet
   */
  static readonly ApiOutlookMessagesLatestNGetPath = '/api/Outlook/messages-latest-n';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookMessagesLatestNGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookMessagesLatestNGet$Plain$Response(params?: {
    'n'?: number;
  }): Observable<StrictHttpResponse<MessagesResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookMessagesLatestNGetPath, 'get');
    if (params) {
      rb.query('n', params['n'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookMessagesLatestNGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookMessagesLatestNGet$Plain(params?: {
    'n'?: number;
  }): Observable<MessagesResponseBaseResponse> {

    return this.apiOutlookMessagesLatestNGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponseBaseResponse>) => r.body as MessagesResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookMessagesLatestNGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookMessagesLatestNGet$Json$Response(params?: {
    'n'?: number;
  }): Observable<StrictHttpResponse<MessagesResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookMessagesLatestNGetPath, 'get');
    if (params) {
      rb.query('n', params['n'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookMessagesLatestNGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookMessagesLatestNGet$Json(params?: {
    'n'?: number;
  }): Observable<MessagesResponseBaseResponse> {

    return this.apiOutlookMessagesLatestNGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponseBaseResponse>) => r.body as MessagesResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookInboxSinceSyncGet
   */
  static readonly ApiOutlookInboxSinceSyncGetPath = '/api/Outlook/inbox-since-sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookInboxSinceSyncGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookInboxSinceSyncGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<MessagesResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookInboxSinceSyncGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookInboxSinceSyncGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookInboxSinceSyncGet$Plain(params?: {
  }): Observable<MessagesResponseBaseResponse> {

    return this.apiOutlookInboxSinceSyncGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponseBaseResponse>) => r.body as MessagesResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookInboxSinceSyncGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookInboxSinceSyncGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<MessagesResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookInboxSinceSyncGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookInboxSinceSyncGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookInboxSinceSyncGet$Json(params?: {
  }): Observable<MessagesResponseBaseResponse> {

    return this.apiOutlookInboxSinceSyncGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponseBaseResponse>) => r.body as MessagesResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookSendEmailGet
   */
  static readonly ApiOutlookSendEmailGetPath = '/api/Outlook/send-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookSendEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookSendEmailGet$Plain$Response(params?: {
    toAddress?: string;
    subject?: string;
    bodyContent?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookSendEmailGetPath, 'get');
    if (params) {
      rb.query('toAddress', params.toAddress, {});
      rb.query('subject', params.subject, {});
      rb.query('bodyContent', params.bodyContent, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookSendEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookSendEmailGet$Plain(params?: {
    toAddress?: string;
    subject?: string;
    bodyContent?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiOutlookSendEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookSendEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookSendEmailGet$Json$Response(params?: {
    toAddress?: string;
    subject?: string;
    bodyContent?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookSendEmailGetPath, 'get');
    if (params) {
      rb.query('toAddress', params.toAddress, {});
      rb.query('subject', params.subject, {});
      rb.query('bodyContent', params.bodyContent, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookSendEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookSendEmailGet$Json(params?: {
    toAddress?: string;
    subject?: string;
    bodyContent?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiOutlookSendEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookCalendarIndividualGet
   */
  static readonly ApiOutlookCalendarIndividualGetPath = '/api/Outlook/calendar-individual';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCalendarIndividualGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookCalendarIndividualGet$Plain$Response(params?: {
    UserEmail?: string;
    FromDate?: string;
    ToDate?: string;
  }): Observable<StrictHttpResponse<CalendarEventsResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCalendarIndividualGetPath, 'get');
    if (params) {
      rb.query('UserEmail', params.UserEmail, {});
      rb.query('FromDate', params.FromDate, {});
      rb.query('ToDate', params.ToDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventsResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCalendarIndividualGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookCalendarIndividualGet$Plain(params?: {
    UserEmail?: string;
    FromDate?: string;
    ToDate?: string;
  }): Observable<CalendarEventsResponseBaseResponse> {

    return this.apiOutlookCalendarIndividualGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsResponseBaseResponse>) => r.body as CalendarEventsResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCalendarIndividualGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookCalendarIndividualGet$Json$Response(params?: {
    UserEmail?: string;
    FromDate?: string;
    ToDate?: string;
  }): Observable<StrictHttpResponse<CalendarEventsResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCalendarIndividualGetPath, 'get');
    if (params) {
      rb.query('UserEmail', params.UserEmail, {});
      rb.query('FromDate', params.FromDate, {});
      rb.query('ToDate', params.ToDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventsResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCalendarIndividualGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookCalendarIndividualGet$Json(params?: {
    UserEmail?: string;
    FromDate?: string;
    ToDate?: string;
  }): Observable<CalendarEventsResponseBaseResponse> {

    return this.apiOutlookCalendarIndividualGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsResponseBaseResponse>) => r.body as CalendarEventsResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookCalendarCombinedPost
   */
  static readonly ApiOutlookCalendarCombinedPostPath = '/api/Outlook/calendar-combined';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCalendarCombinedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCalendarCombinedPost$Plain$Response(params?: {
    body?: CombinedCalendarRequestDto
  }): Observable<StrictHttpResponse<CalendarEventsResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCalendarCombinedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventsResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCalendarCombinedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCalendarCombinedPost$Plain(params?: {
    body?: CombinedCalendarRequestDto
  }): Observable<CalendarEventsResponseBaseResponse> {

    return this.apiOutlookCalendarCombinedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsResponseBaseResponse>) => r.body as CalendarEventsResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCalendarCombinedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCalendarCombinedPost$Json$Response(params?: {
    body?: CombinedCalendarRequestDto
  }): Observable<StrictHttpResponse<CalendarEventsResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCalendarCombinedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventsResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCalendarCombinedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCalendarCombinedPost$Json(params?: {
    body?: CombinedCalendarRequestDto
  }): Observable<CalendarEventsResponseBaseResponse> {

    return this.apiOutlookCalendarCombinedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsResponseBaseResponse>) => r.body as CalendarEventsResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookCreateEventPost
   */
  static readonly ApiOutlookCreateEventPostPath = '/api/Outlook/create-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCreateEventPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCreateEventPost$Plain$Response(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<StrictHttpResponse<CalendarEventBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCreateEventPostPath, 'post');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCreateEventPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCreateEventPost$Plain(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<CalendarEventBaseResponse> {

    return this.apiOutlookCreateEventPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventBaseResponse>) => r.body as CalendarEventBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookCreateEventPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCreateEventPost$Json$Response(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<StrictHttpResponse<CalendarEventBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookCreateEventPostPath, 'post');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookCreateEventPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookCreateEventPost$Json(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<CalendarEventBaseResponse> {

    return this.apiOutlookCreateEventPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventBaseResponse>) => r.body as CalendarEventBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookUpdateEventPut
   */
  static readonly ApiOutlookUpdateEventPutPath = '/api/Outlook/update-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookUpdateEventPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookUpdateEventPut$Plain$Response(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<StrictHttpResponse<CalendarEventBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookUpdateEventPutPath, 'put');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookUpdateEventPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookUpdateEventPut$Plain(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<CalendarEventBaseResponse> {

    return this.apiOutlookUpdateEventPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventBaseResponse>) => r.body as CalendarEventBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookUpdateEventPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookUpdateEventPut$Json$Response(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<StrictHttpResponse<CalendarEventBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookUpdateEventPutPath, 'put');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalendarEventBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookUpdateEventPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutlookUpdateEventPut$Json(params?: {
    userEmail?: string;
    body?: CalendarEventFull
  }): Observable<CalendarEventBaseResponse> {

    return this.apiOutlookUpdateEventPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventBaseResponse>) => r.body as CalendarEventBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookDeleteEventDelete
   */
  static readonly ApiOutlookDeleteEventDeletePath = '/api/Outlook/delete-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookDeleteEventDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookDeleteEventDelete$Plain$Response(params?: {
    userEmail?: string;
    eventId?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookDeleteEventDeletePath, 'delete');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.query('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookDeleteEventDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookDeleteEventDelete$Plain(params?: {
    userEmail?: string;
    eventId?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiOutlookDeleteEventDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookDeleteEventDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookDeleteEventDelete$Json$Response(params?: {
    userEmail?: string;
    eventId?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookDeleteEventDeletePath, 'delete');
    if (params) {
      rb.query('userEmail', params.userEmail, {});
      rb.query('eventId', params.eventId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookDeleteEventDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookDeleteEventDelete$Json(params?: {
    userEmail?: string;
    eventId?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiOutlookDeleteEventDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiOutlookUsersGet
   */
  static readonly ApiOutlookUsersGetPath = '/api/Outlook/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookUsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookUsersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<OutlookUserListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookUsersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutlookUserListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookUsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookUsersGet$Plain(params?: {
  }): Observable<OutlookUserListBaseResponse> {

    return this.apiOutlookUsersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutlookUserListBaseResponse>) => r.body as OutlookUserListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutlookUsersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookUsersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<OutlookUserListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OutlookService.ApiOutlookUsersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutlookUserListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutlookUsersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutlookUsersGet$Json(params?: {
  }): Observable<OutlookUserListBaseResponse> {

    return this.apiOutlookUsersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OutlookUserListBaseResponse>) => r.body as OutlookUserListBaseResponse)
    );
  }

}
