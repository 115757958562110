/* tslint:disable */
/* eslint-disable */

/**
 * 0 = SEPA
 * 
 * 1 = SWIFT
 */
export enum PaymentType {
  SEPA = 0,
  SWIFT = 1
}
