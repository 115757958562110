/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { ToDoItemDto } from '../models/to-do-item-dto';
import { ToDoItemDtoBaseResponse } from '../models/to-do-item-dto-base-response';
import { ToDoItemDtoListBaseResponse } from '../models/to-do-item-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class ToDoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiToDoItemsForUserGet
   */
  static readonly ApiToDoItemsForUserGetPath = '/api/ToDo/items-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoItemsForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoItemsForUserGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<ToDoItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoItemsForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoItemsForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoItemsForUserGet$Plain(params?: {
    userId?: number;
  }): Observable<ToDoItemDtoListBaseResponse> {

    return this.apiToDoItemsForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoListBaseResponse>) => r.body as ToDoItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoItemsForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoItemsForUserGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<ToDoItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoItemsForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoItemsForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoItemsForUserGet$Json(params?: {
    userId?: number;
  }): Observable<ToDoItemDtoListBaseResponse> {

    return this.apiToDoItemsForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoListBaseResponse>) => r.body as ToDoItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiToDoCreateItemPost
   */
  static readonly ApiToDoCreateItemPostPath = '/api/ToDo/create-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoCreateItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoCreateItemPost$Plain$Response(params?: {
    body?: ToDoItemDto
  }): Observable<StrictHttpResponse<ToDoItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoCreateItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoCreateItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoCreateItemPost$Plain(params?: {
    body?: ToDoItemDto
  }): Observable<ToDoItemDtoBaseResponse> {

    return this.apiToDoCreateItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoBaseResponse>) => r.body as ToDoItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoCreateItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoCreateItemPost$Json$Response(params?: {
    body?: ToDoItemDto
  }): Observable<StrictHttpResponse<ToDoItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoCreateItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoCreateItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoCreateItemPost$Json(params?: {
    body?: ToDoItemDto
  }): Observable<ToDoItemDtoBaseResponse> {

    return this.apiToDoCreateItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoBaseResponse>) => r.body as ToDoItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiToDoUpdateItemPut
   */
  static readonly ApiToDoUpdateItemPutPath = '/api/ToDo/update-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoUpdateItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoUpdateItemPut$Plain$Response(params?: {
    body?: ToDoItemDto
  }): Observable<StrictHttpResponse<ToDoItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoUpdateItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoUpdateItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoUpdateItemPut$Plain(params?: {
    body?: ToDoItemDto
  }): Observable<ToDoItemDtoBaseResponse> {

    return this.apiToDoUpdateItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoBaseResponse>) => r.body as ToDoItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoUpdateItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoUpdateItemPut$Json$Response(params?: {
    body?: ToDoItemDto
  }): Observable<StrictHttpResponse<ToDoItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoUpdateItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToDoItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoUpdateItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiToDoUpdateItemPut$Json(params?: {
    body?: ToDoItemDto
  }): Observable<ToDoItemDtoBaseResponse> {

    return this.apiToDoUpdateItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ToDoItemDtoBaseResponse>) => r.body as ToDoItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiToDoUpdateItemCompletedPost
   */
  static readonly ApiToDoUpdateItemCompletedPostPath = '/api/ToDo/update-item-completed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoUpdateItemCompletedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoUpdateItemCompletedPost$Plain$Response(params?: {
    toDoItemId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoUpdateItemCompletedPostPath, 'post');
    if (params) {
      rb.query('toDoItemId', params.toDoItemId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoUpdateItemCompletedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoUpdateItemCompletedPost$Plain(params?: {
    toDoItemId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiToDoUpdateItemCompletedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoUpdateItemCompletedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoUpdateItemCompletedPost$Json$Response(params?: {
    toDoItemId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoUpdateItemCompletedPostPath, 'post');
    if (params) {
      rb.query('toDoItemId', params.toDoItemId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoUpdateItemCompletedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoUpdateItemCompletedPost$Json(params?: {
    toDoItemId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiToDoUpdateItemCompletedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiToDoArchiveItemDelete
   */
  static readonly ApiToDoArchiveItemDeletePath = '/api/ToDo/archive-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoArchiveItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoArchiveItemDelete$Plain$Response(params?: {
    toDoItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoArchiveItemDeletePath, 'delete');
    if (params) {
      rb.query('toDoItemId', params.toDoItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoArchiveItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoArchiveItemDelete$Plain(params?: {
    toDoItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiToDoArchiveItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiToDoArchiveItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoArchiveItemDelete$Json$Response(params?: {
    toDoItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ToDoService.ApiToDoArchiveItemDeletePath, 'delete');
    if (params) {
      rb.query('toDoItemId', params.toDoItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiToDoArchiveItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiToDoArchiveItemDelete$Json(params?: {
    toDoItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiToDoArchiveItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
