/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Day
 * 
 * 1 = Week
 * 
 * 2 = Month
 */
export enum TimePeriod {
  Day = 0,
  Week = 1,
  Month = 2
}
