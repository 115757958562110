/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { BulletinDto } from '../models/bulletin-dto';
import { BulletinDtoBaseResponse } from '../models/bulletin-dto-base-response';
import { BulletinDtoListBaseResponse } from '../models/bulletin-dto-list-base-response';
import { BulletinReactionGroupDtoListBaseResponse } from '../models/bulletin-reaction-group-dto-list-base-response';
import { ReactionEmoji } from '../models/reaction-emoji';

@Injectable({
  providedIn: 'root',
})
export class BulletinService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiBulletinAllBulletinsGet
   */
  static readonly ApiBulletinAllBulletinsGetPath = '/api/Bulletin/all-bulletins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinAllBulletinsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinAllBulletinsGet$Plain$Response(params?: {
    currentUserId?: number;
  }): Observable<StrictHttpResponse<BulletinDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinAllBulletinsGetPath, 'get');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinAllBulletinsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinAllBulletinsGet$Plain(params?: {
    currentUserId?: number;
  }): Observable<BulletinDtoListBaseResponse> {

    return this.apiBulletinAllBulletinsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoListBaseResponse>) => r.body as BulletinDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinAllBulletinsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinAllBulletinsGet$Json$Response(params?: {
    currentUserId?: number;
  }): Observable<StrictHttpResponse<BulletinDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinAllBulletinsGetPath, 'get');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinAllBulletinsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinAllBulletinsGet$Json(params?: {
    currentUserId?: number;
  }): Observable<BulletinDtoListBaseResponse> {

    return this.apiBulletinAllBulletinsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoListBaseResponse>) => r.body as BulletinDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinBulletinReactionsGet
   */
  static readonly ApiBulletinBulletinReactionsGetPath = '/api/Bulletin/bulletin-reactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinBulletinReactionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinBulletinReactionsGet$Plain$Response(params?: {
    bulletinId?: number;
  }): Observable<StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinBulletinReactionsGetPath, 'get');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinBulletinReactionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinBulletinReactionsGet$Plain(params?: {
    bulletinId?: number;
  }): Observable<BulletinReactionGroupDtoListBaseResponse> {

    return this.apiBulletinBulletinReactionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>) => r.body as BulletinReactionGroupDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinBulletinReactionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinBulletinReactionsGet$Json$Response(params?: {
    bulletinId?: number;
  }): Observable<StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinBulletinReactionsGetPath, 'get');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinBulletinReactionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinBulletinReactionsGet$Json(params?: {
    bulletinId?: number;
  }): Observable<BulletinReactionGroupDtoListBaseResponse> {

    return this.apiBulletinBulletinReactionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinReactionGroupDtoListBaseResponse>) => r.body as BulletinReactionGroupDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinCreateBulletinPost
   */
  static readonly ApiBulletinCreateBulletinPostPath = '/api/Bulletin/create-bulletin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinCreateBulletinPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinCreateBulletinPost$Plain$Response(params?: {
    body?: BulletinDto
  }): Observable<StrictHttpResponse<BulletinDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinCreateBulletinPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinCreateBulletinPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinCreateBulletinPost$Plain(params?: {
    body?: BulletinDto
  }): Observable<BulletinDtoBaseResponse> {

    return this.apiBulletinCreateBulletinPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoBaseResponse>) => r.body as BulletinDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinCreateBulletinPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinCreateBulletinPost$Json$Response(params?: {
    body?: BulletinDto
  }): Observable<StrictHttpResponse<BulletinDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinCreateBulletinPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinCreateBulletinPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinCreateBulletinPost$Json(params?: {
    body?: BulletinDto
  }): Observable<BulletinDtoBaseResponse> {

    return this.apiBulletinCreateBulletinPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoBaseResponse>) => r.body as BulletinDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinUpdateBulletinPut
   */
  static readonly ApiBulletinUpdateBulletinPutPath = '/api/Bulletin/update-bulletin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinUpdateBulletinPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinUpdateBulletinPut$Plain$Response(params?: {
    body?: BulletinDto
  }): Observable<StrictHttpResponse<BulletinDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinUpdateBulletinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinUpdateBulletinPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinUpdateBulletinPut$Plain(params?: {
    body?: BulletinDto
  }): Observable<BulletinDtoBaseResponse> {

    return this.apiBulletinUpdateBulletinPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoBaseResponse>) => r.body as BulletinDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinUpdateBulletinPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinUpdateBulletinPut$Json$Response(params?: {
    body?: BulletinDto
  }): Observable<StrictHttpResponse<BulletinDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinUpdateBulletinPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulletinDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinUpdateBulletinPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiBulletinUpdateBulletinPut$Json(params?: {
    body?: BulletinDto
  }): Observable<BulletinDtoBaseResponse> {

    return this.apiBulletinUpdateBulletinPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BulletinDtoBaseResponse>) => r.body as BulletinDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinArchiveBulletinDelete
   */
  static readonly ApiBulletinArchiveBulletinDeletePath = '/api/Bulletin/archive-bulletin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinArchiveBulletinDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinArchiveBulletinDelete$Plain$Response(params?: {
    bulletinId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinArchiveBulletinDeletePath, 'delete');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinArchiveBulletinDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinArchiveBulletinDelete$Plain(params?: {
    bulletinId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinArchiveBulletinDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinArchiveBulletinDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinArchiveBulletinDelete$Json$Response(params?: {
    bulletinId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinArchiveBulletinDeletePath, 'delete');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinArchiveBulletinDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinArchiveBulletinDelete$Json(params?: {
    bulletinId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinArchiveBulletinDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinReactPut
   */
  static readonly ApiBulletinReactPutPath = '/api/Bulletin/react';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinReactPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinReactPut$Plain$Response(params?: {
    bulletinId?: number;
    userId?: number;

    /**
     * 0 &#x3D; Celebrate
     * 
     * 1 &#x3D; Cheer
     * 
     * 2 &#x3D; Strong
     * 
     * 3 &#x3D; Heart
     * 
     * 4 &#x3D; Stars
     * 
     * 5 &#x3D; Rocket
     */
    reaction?: ReactionEmoji;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinReactPutPath, 'put');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
      rb.query('userId', params.userId, {});
      rb.query('reaction', params.reaction, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinReactPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinReactPut$Plain(params?: {
    bulletinId?: number;
    userId?: number;

    /**
     * 0 &#x3D; Celebrate
     * 
     * 1 &#x3D; Cheer
     * 
     * 2 &#x3D; Strong
     * 
     * 3 &#x3D; Heart
     * 
     * 4 &#x3D; Stars
     * 
     * 5 &#x3D; Rocket
     */
    reaction?: ReactionEmoji;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinReactPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinReactPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinReactPut$Json$Response(params?: {
    bulletinId?: number;
    userId?: number;

    /**
     * 0 &#x3D; Celebrate
     * 
     * 1 &#x3D; Cheer
     * 
     * 2 &#x3D; Strong
     * 
     * 3 &#x3D; Heart
     * 
     * 4 &#x3D; Stars
     * 
     * 5 &#x3D; Rocket
     */
    reaction?: ReactionEmoji;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinReactPutPath, 'put');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
      rb.query('userId', params.userId, {});
      rb.query('reaction', params.reaction, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinReactPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinReactPut$Json(params?: {
    bulletinId?: number;
    userId?: number;

    /**
     * 0 &#x3D; Celebrate
     * 
     * 1 &#x3D; Cheer
     * 
     * 2 &#x3D; Strong
     * 
     * 3 &#x3D; Heart
     * 
     * 4 &#x3D; Stars
     * 
     * 5 &#x3D; Rocket
     */
    reaction?: ReactionEmoji;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinReactPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiBulletinRemoveReactionDelete
   */
  static readonly ApiBulletinRemoveReactionDeletePath = '/api/Bulletin/remove-reaction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinRemoveReactionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinRemoveReactionDelete$Plain$Response(params?: {
    bulletinId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinRemoveReactionDeletePath, 'delete');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinRemoveReactionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinRemoveReactionDelete$Plain(params?: {
    bulletinId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinRemoveReactionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiBulletinRemoveReactionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinRemoveReactionDelete$Json$Response(params?: {
    bulletinId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BulletinService.ApiBulletinRemoveReactionDeletePath, 'delete');
    if (params) {
      rb.query('bulletinId', params.bulletinId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiBulletinRemoveReactionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiBulletinRemoveReactionDelete$Json(params?: {
    bulletinId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiBulletinRemoveReactionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
