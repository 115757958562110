import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { AccountService } from "app/api/services";
import { User } from "app/core/models/user.model";
import { JwtAuthService } from "app/core/services/cookie-jwt-auth/jwt-auth.service";
import { ProjectHelper } from "app/views/admin/pages/projects/project/project.helper";

export class GenericCommentDto {
  public id?: number;
  public parentId?: number;
  public text?: string
  public userId?: number;
  public timeStamp?: Date;
}

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent extends ProjectHelper implements OnInit {
  @Input() comments: GenericCommentDto[] = [];
  
  @Input() parentId: number;
  @Input() titleClass: string = ''; // empty - use mat-label
  @Output() $commentAdded = new EventEmitter<GenericCommentDto>();
  @Output() $commentChanged = new EventEmitter<GenericCommentDto>();
  @Output() $commentDeleted = new EventEmitter<number>();

  public currentText: string = '';
  public currentId: number = null;
  public currentUser: User;
  public editingComment: boolean = false;

  constructor(
    public accountService: AccountService,
    public cdr: ChangeDetectorRef,
    public jwtAuth: JwtAuthService,
    public _fuseConfirmationService: FuseConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadUsers();

    this.currentUser = this.jwtAuth.getUser();
  }

  loadUsers() {
    this.accountService.apiAccountSearchAllGet$Json$Response().subscribe(res => {
      if(!res?.body.data) return;

      this.allUsers = res.body.data;

      this.cdr.detectChanges();
    });
  }

  openCommentEdit(comment: GenericCommentDto) {
    this.currentId = comment.id;
    this.currentText = comment.text;
    this.editingComment = true;
    this.cdr.detectChanges();
  }

  saveButtonClicked() {
    this.editingComment ? this.updateComment() : this.addComment();
  }

  addComment() {
    if(this.currentText.trim() === '') return;

    var newComment = <GenericCommentDto> {
      parentId: this.parentId,
      userId: Number(this.currentUser.id),
      text: this.currentText,
      timeStamp: new Date(),
    };

    this.commentAdded(newComment);
    this.wipeComment();
  }

  updateComment() {
    var comment = <GenericCommentDto> {
      id: this.currentId,
      parentId: this.parentId,
      userId: Number(this.currentUser.id),
      text: this.currentText,
      timeStamp: new Date(),
    };

    this.commentChanged(comment);
    this.wipeComment();
  }

  deleteComment(id: number) {
    const confirmation = this._fuseConfirmationService.open({
      title  : 'Delete Comment',
      message: `Are you sure you want to remove this comment?`,
      actions: {
          confirm: {
              label: 'Delete',
          },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed')
        this.commentDeleted(id);
    });
  }

  wipeComment() {
    this.currentId = null;
    this.currentText = '';
    this.editingComment = false;
    this.cdr.detectChanges();
  }

  canEditComment(comment: GenericCommentDto) {
    return comment?.userId == Number(this.currentUser?.id);
    //perhaps allow admins/superadmins to always edit/delete comments, or some other rules here
  }

  private commentAdded = (comment: GenericCommentDto): void => this.$commentAdded.emit(comment);
  private commentChanged = (comment: GenericCommentDto): void => this.$commentChanged.emit(comment);
  private commentDeleted =(id: number): void => this.$commentDeleted.emit(id);

}
