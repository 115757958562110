/* tslint:disable */
/* eslint-disable */

/**
 * 0 = GBP
 * 
 * 1 = USD
 * 
 * 2 = EUR
 */
export enum CurrencyType {
  GBP = 0,
  USD = 1,
  EUR = 2
}
