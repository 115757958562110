/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:dashboard',
        link : '/dashboard',
    },
    {
        id   : 'projects',
        title: 'Projects',
        type : 'basic',
        icon : 'mat_outline:bubble_chart',
        link : '/pages/projects',
    },
    {
        id   : 'crm',
        title: 'CRM',
        type : 'basic',
        icon : 'heroicons_outline:rocket-launch',
        link : '/pages/crm',
    },
    {
        id   : 'workload',
        title: 'Workload',
        type : 'basic',
        icon : 'mat_outline:table_chart',
        link : '/pages/workload',
    },
    {
        id   : 'calendar',
        title: 'Calendar',
        type : 'basic',
        icon : 'heroicons_outline:calendar-days',
        link : '/pages/calendar',
    },
    {
        id   : 'invoices',
        title: 'Invoices',
        type : 'basic',
        icon : 'heroicons_outline:document-text',
        link : '/pages/invoicing',
    },
    {
        id   : 'purchaseOrders',
        title: 'Purchase Orders',
        type : 'basic',
        icon : 'heroicons_outline:document', //mat_outline:receipt_long
        link : '/pages/purchase-orders',
    },
    {
        id   : 'reports',
        title: 'Reports',
        type : 'basic',
        icon : 'mat_outline:insert_chart',
        link : '/pages/reporting',
    },
    {
        id   : 'historicData',
        title: 'Historic Data',
        type : 'basic',
        icon : 'feather:archive',
        link : '/pages/historic',
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        icon : 'heroicons_outline:cog-8-tooth',
        link : '/pages/settings',
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'all',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'all',
        title   : 'PAGES',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
// [
//     {
//         id      : 'all',
//         title   : 'Pages',
//         type    : 'group',
//         icon    : 'heroicons_outline:home',
//         children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
//     },
// ];
