/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Admin
 * 
 * 1 = User
 */
export enum Roles {
  Admin = 0,
  User = 1
}
