/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 * 
 * 1 = OneAndAHalf
 * 
 * 2 = Twice
 */
export enum TimeAndAHalf {
  None = 0,
  OneAndAHalf = 1,
  Twice = 2
}
