/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { BudgetService } from './services/budget.service';
import { CompanyService } from './services/company.service';
import { ContactService } from './services/contact.service';
import { CostManagerService } from './services/cost-manager.service';
import { GithubService } from './services/github.service';
import { InvoiceService } from './services/invoice.service';
import { ProjectService } from './services/project.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { ReportService } from './services/report.service';
import { ResourceService } from './services/resource.service';
import { StaffRateService } from './services/staff-rate.service';
import { ToDoService } from './services/to-do.service';
import { WorkloadService } from './services/workload.service';
import { XeroService } from './services/xero.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    BudgetService,
    CompanyService,
    ContactService,
    CostManagerService,
    GithubService,
    InvoiceService,
    ProjectService,
    PurchaseOrderService,
    ReportService,
    ResourceService,
    StaffRateService,
    ToDoService,
    WorkloadService,
    XeroService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
