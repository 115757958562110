import { Component, Input } from "@angular/core";

@Component({
  selector: "app-sort-chevrons",
  templateUrl: "./sort-chevrons.component.html",
  styles: [`:host { height: 24px; }`],
})
export class SortChevronsComponent {
  @Input() isSorting: boolean = false;
  @Input() isAsc: boolean = true;

  constructor() {}
}
