export class TimeUtil {

    public static getHoursText(hours: number) {
        if(!hours) return '0h'; //None';

        var hr = Math.trunc(hours);
        var mn = Math.round((hours % 1) * 60);

        return (hr > 0 ? `${hr}h ` : '') + (mn > 0 ? `${mn}m` : '');
    }

    public static getHoursFromHoursText(text: string) {
        if(!text.includes('h') && !text.includes('m')) {
            const dregex = /(\d+(.\d+)?)/g;
            const dfound = text.match(dregex);
            return dfound?.length ? dfound[0] : '0';
        }

        const hregex = /(\d+(.\d+)?)h/g;
        const hfound = text.match(hregex);
        var hours = hfound?.length ? hfound[0] : '0';

        const mregex = /(\d+)m/g;
        const mfound = text.match(mregex);
        var mins = mfound?.length ? mfound[0] : '0';

        if(!hfound && !mfound) return '0';

        return (Number.parseFloat(hours) + Number.parseInt(mins) / 60).toFixed(2);
    }

    public static getDaysBetween(date1: Date, date2: Date) {
        return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

    public static getUniqueDaysBetween(date1: Date, date2: Date) {
        return TimeUtil.getDaysBetween(date1, date2) + 1;
    }

    public static addHours(date: Date, hours: number): Date {
        date.setTime(date.getTime() + (hours*60*60*1000));
        return date;
    }

    public static setTo1amUTC(date: Date) {
        if(date.getHours() == 0)
            TimeUtil.addHours(date, 1);
        date.setHours(2, 0, 0, 0);
        return date;
    }

    public static setTo2359UTC(date: Date) {
        TimeUtil.setTo1amUTC(date);
        date.setHours(23, 59, 0, 0);
        return date;
    }

    public static setTo1stOfMonth(date: Date) {
        TimeUtil.setTo1amUTC(date);
        date.setDate(1);
        return date;
    }

    public static setToEndOfMonth(date: Date) {
        TimeUtil.setTo1stOfMonth(date);
        TimeUtil.addMonths(date, 1);
        TimeUtil.addDays(date, -1);
        TimeUtil.setTo2359UTC(date);
        return date;
    }

    public static setToStartOfWeek(date: Date) {
        TimeUtil.setTo1amUTC(date);
        TimeUtil.addDays(date, 1 + date.getDay() * -1);
        return date;
    }

    public static setToEndOfWeek(date: Date) {
        TimeUtil.setToStartOfWeek(date);
        TimeUtil.addDays(date, 6);
        TimeUtil.setTo2359UTC(date);
        return date;
    }
    
    public static addDays(date: Date, days: number) {
        date.setTime(date.getTime() + (days*24*60*60*1000));
        return date;
    }

    public static addMonths(date: Date, months: number) {
        var previousMonth = date.getMonth();
        var newMonth = previousMonth + months;
        var newYear = newMonth >= 12 ? date.getFullYear() + 1 : date.getFullYear();

        var newDate = new Date(newYear, newMonth % 12, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }
    
    public static addYears(date: Date, years: number) {
        var newDate = new Date(date.getFullYear() + years, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }

    public static getEndOfCurrentYear() {
        var yearStart = TimeUtil.getStartOfCurrentYear();
        return TimeUtil.addHours(TimeUtil.addYears(yearStart, 1), -1/60/60);
    }

    public static getStartOfCurrentYear() {
        var now = new Date();
        return new Date(now.getFullYear(), 0, 1, 0, 0, 0);
    }

    public static getDaysOverlap(from1: Date, to1: Date, from2: Date, to2: Date) {
        if(from1 >= from2 && to1 <= to2)
            return TimeUtil.getDaysBetween(from1, to1);

        var start = Math.max(from1.getTime(), from2.getTime());
        var end = Math.min(to1.getTime(), to2.getTime());

        var days = end - start;

        return Math.round(days / (60*60*24*1000));
    }

    public static getDaysOverlapInCurrentYear(from: Date, to: Date) {
        return this.getDaysOverlap(from, to, TimeUtil.getStartOfCurrentYear(), TimeUtil.getStartOfCurrentYear());
        
        if(from >= TimeUtil.getStartOfCurrentYear() && to <= TimeUtil.getEndOfCurrentYear())
            return TimeUtil.getDaysBetween(from, to);

        var start = Math.max(from.getTime(), TimeUtil.getStartOfCurrentYear().getTime());
        var end = Math.min(to.getTime(), TimeUtil.getEndOfCurrentYear().getTime());

        var days = end - start;

        return Math.round(days / (60*60*24*1000));
    }

    public static splitDateAndTime(date: Date) {
        var newDate = TimeUtil.setTo1amUTC(new Date(date));
        var time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

        return {date: newDate, time: time};
    }

    public static collateDateAndTimeStrings(date: string, time: string) {
        var newDate = TimeUtil.setTo1amUTC(new Date(date));
        var hours = +time.split(':')[0] + (+time.split(':')[1] / 60);

        newDate.setHours(0);
        return TimeUtil.addHours(newDate, hours);

        // return TimeUtil.addHours(new Date(date), +time.split(':')[0] + (+time.split(':')[1] / 60))
    }

    public static isCurrentMonth(date: Date) {
        return date.getMonth() == new Date().getMonth() && date.getFullYear() == new Date().getFullYear();
    }

    public static getTimeStringArray(startHour: number, endHour: number) {
        var n = (endHour - startHour) * 2 + 1;
        var startI = startHour * 2 + 1;

        return new Array(n).fill(null).map((_, i) => (i + startI) / 2 - 0.5).map(x => `${Math.floor(x).toString().padStart(2, '0')}:${x % 1 == 0 ? '00' : '30'}`);
    }

    public static getNearestHourFraction(date: Date, hours: number) {
        var ms = 3600000 * hours;
        return new Date(Math.ceil(date.getTime() / ms) * ms);
    }

}
