/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Draft
 * 
 * 1 = Sent
 * 
 * 2 = Paid
 * 
 * 3 = Overdue
 * 
 * 4 = Cancelled
 */
export enum InvoiceStatus {
  Draft = 0,
  Sent = 1,
  Paid = 2,
  Overdue = 3,
  Cancelled = 4
}
