/* tslint:disable */
/* eslint-disable */

/**
 * 0 = Zero
 * 
 * 1 = Reduced
 * 
 * 2 = Standard
 */
export enum VatOption {
  Zero = 0,
  Reduced = 1,
  Standard = 2
}
