/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CostItemActualCostRequestDto } from '../models/cost-item-actual-cost-request-dto';
import { CostItemActualCostResponseDtoListBaseResponse } from '../models/cost-item-actual-cost-response-dto-list-base-response';
import { CostManagerDto } from '../models/cost-manager-dto';
import { CostManagerDtoBaseResponse } from '../models/cost-manager-dto-base-response';
import { CostManagerDtoListBaseResponse } from '../models/cost-manager-dto-list-base-response';
import { CostManagerListDto } from '../models/cost-manager-list-dto';
import { CostManagerListDtoListBaseResponse } from '../models/cost-manager-list-dto-list-base-response';
import { CostManagerMilestoneDto } from '../models/cost-manager-milestone-dto';
import { CostManagerMilestoneDtoBaseResponse } from '../models/cost-manager-milestone-dto-base-response';
import { CostManagerMilestoneDtoListBaseResponse } from '../models/cost-manager-milestone-dto-list-base-response';
import { CostSectionDto } from '../models/cost-section-dto';
import { CostSectionDtoBaseResponse } from '../models/cost-section-dto-base-response';
import { CostSectionDtoListBaseResponse } from '../models/cost-section-dto-list-base-response';
import { CostSectionItemDto } from '../models/cost-section-item-dto';
import { CostSectionItemDtoBaseResponse } from '../models/cost-section-item-dto-base-response';
import { CostSectionItemDtoListBaseResponse } from '../models/cost-section-item-dto-list-base-response';
import { DecimalBaseResponse } from '../models/decimal-base-response';
import { Int64ListBaseResponse } from '../models/int-64-list-base-response';
import { LongTermForecastDtoBaseResponse } from '../models/long-term-forecast-dto-base-response';
import { MilestoneNotificationDto } from '../models/milestone-notification-dto';
import { MilestoneNotificationDtoBaseResponse } from '../models/milestone-notification-dto-base-response';
import { MilestoneNotificationDtoListBaseResponse } from '../models/milestone-notification-dto-list-base-response';
import { ResourceCostItemActualCostRequestDto } from '../models/resource-cost-item-actual-cost-request-dto';
import { ResourceCostItemActualCostResponseDtoListBaseResponse } from '../models/resource-cost-item-actual-cost-response-dto-list-base-response';
import { ResourceCostSectionDto } from '../models/resource-cost-section-dto';
import { ResourceCostSectionDtoBaseResponse } from '../models/resource-cost-section-dto-base-response';
import { ResourceCostSectionItemDto } from '../models/resource-cost-section-item-dto';
import { ResourceCostSectionItemDtoBaseResponse } from '../models/resource-cost-section-item-dto-base-response';
import { ResourceCostSectionItemDtoListBaseResponse } from '../models/resource-cost-section-item-dto-list-base-response';
import { SimpleCostSectionDto } from '../models/simple-cost-section-dto';
import { SimpleCostSectionDtoBaseResponse } from '../models/simple-cost-section-dto-base-response';
import { SimpleCostSectionItemDto } from '../models/simple-cost-section-item-dto';
import { SimpleCostSectionItemDtoBaseResponse } from '../models/simple-cost-section-item-dto-base-response';

@Injectable({
  providedIn: 'root',
})
export class CostManagerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCostManagerCostManagerGet
   */
  static readonly ApiCostManagerCostManagerGetPath = '/api/CostManager/cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Plain$Response(params?: {
    costManagerId?: number;
    includeActualCosts?: boolean;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('includeActualCosts', params.includeActualCosts, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Plain(params?: {
    costManagerId?: number;
    includeActualCosts?: boolean;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerCostManagerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Json$Response(params?: {
    costManagerId?: number;
    includeActualCosts?: boolean;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('includeActualCosts', params.includeActualCosts, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerGet$Json(params?: {
    costManagerId?: number;
    includeActualCosts?: boolean;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerCostManagerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCostManagerDelete
   */
  static readonly ApiCostManagerCostManagerDeletePath = '/api/CostManager/cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerDeletePath, 'delete');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Plain(params?: {
    costManagerId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCostManagerDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagerDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagerDeletePath, 'delete');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagerDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagerDelete$Json(params?: {
    costManagerId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCostManagerDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCostManagersForProjectGet
   */
  static readonly ApiCostManagerCostManagersForProjectGetPath = '/api/CostManager/cost-managers-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagersForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagersForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagersForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagersForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagersForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<CostManagerDtoListBaseResponse> {

    return this.apiCostManagerCostManagersForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoListBaseResponse>) => r.body as CostManagerDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostManagersForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagersForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostManagersForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostManagersForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostManagersForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<CostManagerDtoListBaseResponse> {

    return this.apiCostManagerCostManagersForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoListBaseResponse>) => r.body as CostManagerDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerListCostManagersForProjectGet
   */
  static readonly ApiCostManagerListCostManagersForProjectGetPath = '/api/CostManager/list-cost-managers-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerListCostManagersForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerListCostManagersForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerListCostManagersForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerListCostManagersForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerListCostManagersForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<CostManagerListDtoListBaseResponse> {

    return this.apiCostManagerListCostManagersForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerListDtoListBaseResponse>) => r.body as CostManagerListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerListCostManagersForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerListCostManagersForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerListCostManagersForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerListCostManagersForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerListCostManagersForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<CostManagerListDtoListBaseResponse> {

    return this.apiCostManagerListCostManagersForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerListDtoListBaseResponse>) => r.body as CostManagerListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAllBudgetCostManagersGet
   */
  static readonly ApiCostManagerAllBudgetCostManagersGetPath = '/api/CostManager/all-budget-cost-managers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAllBudgetCostManagersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerAllBudgetCostManagersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<CostManagerDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAllBudgetCostManagersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAllBudgetCostManagersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerAllBudgetCostManagersGet$Plain(params?: {
  }): Observable<CostManagerDtoListBaseResponse> {

    return this.apiCostManagerAllBudgetCostManagersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoListBaseResponse>) => r.body as CostManagerDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAllBudgetCostManagersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerAllBudgetCostManagersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<CostManagerDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAllBudgetCostManagersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAllBudgetCostManagersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerAllBudgetCostManagersGet$Json(params?: {
  }): Observable<CostManagerDtoListBaseResponse> {

    return this.apiCostManagerAllBudgetCostManagersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoListBaseResponse>) => r.body as CostManagerDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerBudgetCostManagerForProjectGet
   */
  static readonly ApiCostManagerBudgetCostManagerForProjectGetPath = '/api/CostManager/budget-cost-manager-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerBudgetCostManagerForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerBudgetCostManagerForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerBudgetCostManagerForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerBudgetCostManagerForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerBudgetCostManagerForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerBudgetCostManagerForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerBudgetCostManagerForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerBudgetCostManagerForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<CostManagerDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerBudgetCostManagerForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerBudgetCostManagerForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerBudgetCostManagerForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<CostManagerDtoBaseResponse> {

    return this.apiCostManagerBudgetCostManagerForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerDtoBaseResponse>) => r.body as CostManagerDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet
   */
  static readonly ApiCostManagerItemsWithTimeLogsNotIncludedOnCmGetPath = '/api/CostManager/items-with-time-logs-not-included-on-CM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerItemsWithTimeLogsNotIncludedOnCmGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Plain(params?: {
    costManagerId?: number;
  }): Observable<CostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoListBaseResponse>) => r.body as CostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerItemsWithTimeLogsNotIncludedOnCmGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Json(params?: {
    costManagerId?: number;
  }): Observable<CostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerItemsWithTimeLogsNotIncludedOnCmGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoListBaseResponse>) => r.body as CostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost
   */
  static readonly ApiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPostPath = '/api/CostManager/get-items-with-time-logs-not-included-on-pending-CM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Plain(params?: {
    body?: CostManagerDto
  }): Observable<CostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoListBaseResponse>) => r.body as CostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Json(params?: {
    body?: CostManagerDto
  }): Observable<CostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerGetItemsWithTimeLogsNotIncludedOnPendingCmPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoListBaseResponse>) => r.body as CostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet
   */
  static readonly ApiCostManagerItemsWithDivergentPurchaseOrdersOnCmGetPath = '/api/CostManager/items-with-divergent-purchase-orders-on-CM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerItemsWithDivergentPurchaseOrdersOnCmGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Plain(params?: {
    costManagerId?: number;
  }): Observable<ResourceCostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>) => r.body as ResourceCostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerItemsWithDivergentPurchaseOrdersOnCmGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Json(params?: {
    costManagerId?: number;
  }): Observable<ResourceCostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerItemsWithDivergentPurchaseOrdersOnCmGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>) => r.body as ResourceCostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost
   */
  static readonly ApiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPostPath = '/api/CostManager/get-items-with-divergent-purchase-orders-on-pending-CM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Plain(params?: {
    body?: CostManagerDto
  }): Observable<ResourceCostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>) => r.body as ResourceCostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Json(params?: {
    body?: CostManagerDto
  }): Observable<ResourceCostSectionItemDtoListBaseResponse> {

    return this.apiCostManagerGetItemsWithDivergentPurchaseOrdersOnPendingCmPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoListBaseResponse>) => r.body as ResourceCostSectionItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCostSectionItemActualCostGet
   */
  static readonly ApiCostManagerCostSectionItemActualCostGetPath = '/api/CostManager/cost-section-item-actual-cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostSectionItemActualCostGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostSectionItemActualCostGet$Plain$Response(params?: {
    projectId?: number;
    costSectionName?: string;
    userId?: number;
    staffRateId?: number;
  }): Observable<StrictHttpResponse<DecimalBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostSectionItemActualCostGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('costSectionName', params.costSectionName, {});
      rb.query('userId', params.userId, {});
      rb.query('staffRateId', params.staffRateId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostSectionItemActualCostGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostSectionItemActualCostGet$Plain(params?: {
    projectId?: number;
    costSectionName?: string;
    userId?: number;
    staffRateId?: number;
  }): Observable<DecimalBaseResponse> {

    return this.apiCostManagerCostSectionItemActualCostGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalBaseResponse>) => r.body as DecimalBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostSectionItemActualCostGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostSectionItemActualCostGet$Json$Response(params?: {
    projectId?: number;
    costSectionName?: string;
    userId?: number;
    staffRateId?: number;
  }): Observable<StrictHttpResponse<DecimalBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostSectionItemActualCostGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('costSectionName', params.costSectionName, {});
      rb.query('userId', params.userId, {});
      rb.query('staffRateId', params.staffRateId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostSectionItemActualCostGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerCostSectionItemActualCostGet$Json(params?: {
    projectId?: number;
    costSectionName?: string;
    userId?: number;
    staffRateId?: number;
  }): Observable<DecimalBaseResponse> {

    return this.apiCostManagerCostSectionItemActualCostGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalBaseResponse>) => r.body as DecimalBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCostSectionItemsActualCostsPost
   */
  static readonly ApiCostManagerCostSectionItemsActualCostsPostPath = '/api/CostManager/cost-section-items-actual-costs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostSectionItemsActualCostsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCostSectionItemsActualCostsPost$Plain$Response(params?: {
    projectId?: number;
    body?: Array<CostItemActualCostRequestDto>
  }): Observable<StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostSectionItemsActualCostsPostPath, 'post');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostSectionItemsActualCostsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCostSectionItemsActualCostsPost$Plain(params?: {
    projectId?: number;
    body?: Array<CostItemActualCostRequestDto>
  }): Observable<CostItemActualCostResponseDtoListBaseResponse> {

    return this.apiCostManagerCostSectionItemsActualCostsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>) => r.body as CostItemActualCostResponseDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCostSectionItemsActualCostsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCostSectionItemsActualCostsPost$Json$Response(params?: {
    projectId?: number;
    body?: Array<CostItemActualCostRequestDto>
  }): Observable<StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCostSectionItemsActualCostsPostPath, 'post');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCostSectionItemsActualCostsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCostSectionItemsActualCostsPost$Json(params?: {
    projectId?: number;
    body?: Array<CostItemActualCostRequestDto>
  }): Observable<CostItemActualCostResponseDtoListBaseResponse> {

    return this.apiCostManagerCostSectionItemsActualCostsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostItemActualCostResponseDtoListBaseResponse>) => r.body as CostItemActualCostResponseDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerResourceCostSectionItemsActualCostsPost
   */
  static readonly ApiCostManagerResourceCostSectionItemsActualCostsPostPath = '/api/CostManager/resource-cost-section-items-actual-costs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerResourceCostSectionItemsActualCostsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerResourceCostSectionItemsActualCostsPost$Plain$Response(params?: {
    body?: Array<ResourceCostItemActualCostRequestDto>
  }): Observable<StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerResourceCostSectionItemsActualCostsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerResourceCostSectionItemsActualCostsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerResourceCostSectionItemsActualCostsPost$Plain(params?: {
    body?: Array<ResourceCostItemActualCostRequestDto>
  }): Observable<ResourceCostItemActualCostResponseDtoListBaseResponse> {

    return this.apiCostManagerResourceCostSectionItemsActualCostsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>) => r.body as ResourceCostItemActualCostResponseDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerResourceCostSectionItemsActualCostsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerResourceCostSectionItemsActualCostsPost$Json$Response(params?: {
    body?: Array<ResourceCostItemActualCostRequestDto>
  }): Observable<StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerResourceCostSectionItemsActualCostsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerResourceCostSectionItemsActualCostsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerResourceCostSectionItemsActualCostsPost$Json(params?: {
    body?: Array<ResourceCostItemActualCostRequestDto>
  }): Observable<ResourceCostItemActualCostResponseDtoListBaseResponse> {

    return this.apiCostManagerResourceCostSectionItemsActualCostsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostItemActualCostResponseDtoListBaseResponse>) => r.body as ResourceCostItemActualCostResponseDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerCreateCostManagerPost
   */
  static readonly ApiCostManagerCreateCostManagerPostPath = '/api/CostManager/create-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCreateCostManagerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCreateCostManagerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCreateCostManagerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Plain(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCreateCostManagerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerCreateCostManagerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerCreateCostManagerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerCreateCostManagerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerCreateCostManagerPost$Json(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerCreateCostManagerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateFirstCostManagerPut
   */
  static readonly ApiCostManagerUpdateFirstCostManagerPutPath = '/api/CostManager/update-first-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateFirstCostManagerPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateFirstCostManagerPut$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateFirstCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateFirstCostManagerPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateFirstCostManagerPut$Plain(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateFirstCostManagerPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateFirstCostManagerPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateFirstCostManagerPut$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateFirstCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateFirstCostManagerPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateFirstCostManagerPut$Json(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateFirstCostManagerPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerOverwriteCostManagerPut
   */
  static readonly ApiCostManagerOverwriteCostManagerPutPath = '/api/CostManager/overwrite-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerOverwriteCostManagerPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerOverwriteCostManagerPut$Plain$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerOverwriteCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerOverwriteCostManagerPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerOverwriteCostManagerPut$Plain(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerOverwriteCostManagerPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerOverwriteCostManagerPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerOverwriteCostManagerPut$Json$Response(params?: {
    body?: CostManagerDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerOverwriteCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerOverwriteCostManagerPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerOverwriteCostManagerPut$Json(params?: {
    body?: CostManagerDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerOverwriteCostManagerPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerRenameCostManagerPut
   */
  static readonly ApiCostManagerRenameCostManagerPutPath = '/api/CostManager/rename-cost-manager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerRenameCostManagerPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerRenameCostManagerPut$Plain$Response(params?: {
    body?: CostManagerListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerRenameCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerRenameCostManagerPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerRenameCostManagerPut$Plain(params?: {
    body?: CostManagerListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerRenameCostManagerPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerRenameCostManagerPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerRenameCostManagerPut$Json$Response(params?: {
    body?: CostManagerListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerRenameCostManagerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerRenameCostManagerPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerRenameCostManagerPut$Json(params?: {
    body?: CostManagerListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerRenameCostManagerPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateCurrencyInfoPut
   */
  static readonly ApiCostManagerUpdateCurrencyInfoPutPath = '/api/CostManager/update-currency-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateCurrencyInfoPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCurrencyInfoPut$Plain$Response(params?: {
    body?: CostManagerListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateCurrencyInfoPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateCurrencyInfoPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCurrencyInfoPut$Plain(params?: {
    body?: CostManagerListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateCurrencyInfoPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateCurrencyInfoPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCurrencyInfoPut$Json$Response(params?: {
    body?: CostManagerListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateCurrencyInfoPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateCurrencyInfoPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateCurrencyInfoPut$Json(params?: {
    body?: CostManagerListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateCurrencyInfoPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSectionPost
   */
  static readonly ApiCostManagerAddSectionPostPath = '/api/CostManager/add-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Plain$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<CostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Plain(params?: {
    body?: CostSectionDto
  }): Observable<CostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoBaseResponse>) => r.body as CostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Json$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<CostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionPost$Json(params?: {
    body?: CostSectionDto
  }): Observable<CostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoBaseResponse>) => r.body as CostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddResourceSectionPost
   */
  static readonly ApiCostManagerAddResourceSectionPostPath = '/api/CostManager/add-resource-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddResourceSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionPost$Plain$Response(params?: {
    body?: ResourceCostSectionDto
  }): Observable<StrictHttpResponse<ResourceCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddResourceSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddResourceSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionPost$Plain(params?: {
    body?: ResourceCostSectionDto
  }): Observable<ResourceCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddResourceSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionDtoBaseResponse>) => r.body as ResourceCostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddResourceSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionPost$Json$Response(params?: {
    body?: ResourceCostSectionDto
  }): Observable<StrictHttpResponse<ResourceCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddResourceSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddResourceSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionPost$Json(params?: {
    body?: ResourceCostSectionDto
  }): Observable<ResourceCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddResourceSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionDtoBaseResponse>) => r.body as ResourceCostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSimpleSectionPost
   */
  static readonly ApiCostManagerAddSimpleSectionPostPath = '/api/CostManager/add-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Plain$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<SimpleCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Plain(params?: {
    body?: SimpleCostSectionDto
  }): Observable<SimpleCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionDtoBaseResponse>) => r.body as SimpleCostSectionDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Json$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<SimpleCostSectionDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionPost$Json(params?: {
    body?: SimpleCostSectionDto
  }): Observable<SimpleCostSectionDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionDtoBaseResponse>) => r.body as SimpleCostSectionDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSectionPut
   */
  static readonly ApiCostManagerUpdateSectionPutPath = '/api/CostManager/update-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Plain$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Plain(params?: {
    body?: CostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Json$Response(params?: {
    body?: CostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionPut$Json(params?: {
    body?: CostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateResourceSectionPut
   */
  static readonly ApiCostManagerUpdateResourceSectionPutPath = '/api/CostManager/update-resource-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateResourceSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionPut$Plain$Response(params?: {
    body?: ResourceCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateResourceSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateResourceSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionPut$Plain(params?: {
    body?: ResourceCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateResourceSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateResourceSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionPut$Json$Response(params?: {
    body?: ResourceCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateResourceSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateResourceSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionPut$Json(params?: {
    body?: ResourceCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateResourceSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSimpleSectionPut
   */
  static readonly ApiCostManagerUpdateSimpleSectionPutPath = '/api/CostManager/update-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Plain$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Plain(params?: {
    body?: SimpleCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Json$Response(params?: {
    body?: SimpleCostSectionDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionPut$Json(params?: {
    body?: SimpleCostSectionDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveSectionDelete
   */
  static readonly ApiCostManagerArchiveSectionDeletePath = '/api/CostManager/archive-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Plain$Response(params?: {
    costSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('costSectionId', params.costSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Plain(params?: {
    costSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Json$Response(params?: {
    costSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSectionDeletePath, 'delete');
    if (params) {
      rb.query('costSectionId', params.costSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSectionDelete$Json(params?: {
    costSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveResourceSectionDelete
   */
  static readonly ApiCostManagerArchiveResourceSectionDeletePath = '/api/CostManager/archive-resource-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveResourceSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveResourceSectionDelete$Plain$Response(params?: {
    resourceCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveResourceSectionDeletePath, 'delete');
    if (params) {
      rb.query('resourceCostSectionId', params.resourceCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveResourceSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveResourceSectionDelete$Plain(params?: {
    resourceCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveResourceSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveResourceSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveResourceSectionDelete$Json$Response(params?: {
    resourceCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveResourceSectionDeletePath, 'delete');
    if (params) {
      rb.query('resourceCostSectionId', params.resourceCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveResourceSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveResourceSectionDelete$Json(params?: {
    resourceCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveResourceSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveSimpleSectionDelete
   */
  static readonly ApiCostManagerArchiveSimpleSectionDeletePath = '/api/CostManager/archive-simple-section';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSimpleSectionDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Plain$Response(params?: {
    simpleCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSimpleSectionDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionId', params.simpleCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSimpleSectionDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Plain(params?: {
    simpleCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSimpleSectionDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveSimpleSectionDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Json$Response(params?: {
    simpleCostSectionId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveSimpleSectionDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionId', params.simpleCostSectionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveSimpleSectionDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveSimpleSectionDelete$Json(params?: {
    simpleCostSectionId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveSimpleSectionDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSectionItemPost
   */
  static readonly ApiCostManagerAddSectionItemPostPath = '/api/CostManager/add-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Plain$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Plain(params?: {
    body?: CostSectionItemDto
  }): Observable<CostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoBaseResponse>) => r.body as CostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Json$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<CostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSectionItemPost$Json(params?: {
    body?: CostSectionItemDto
  }): Observable<CostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionItemDtoBaseResponse>) => r.body as CostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSectionItemPut
   */
  static readonly ApiCostManagerUpdateSectionItemPutPath = '/api/CostManager/update-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Plain$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Plain(params?: {
    body?: CostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Json$Response(params?: {
    body?: CostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSectionItemPut$Json(params?: {
    body?: CostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteSectionItemDelete
   */
  static readonly ApiCostManagerDeleteSectionItemDeletePath = '/api/CostManager/delete-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Plain$Response(params?: {
    costSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('costSectionItemId', params.costSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Plain(params?: {
    costSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Json$Response(params?: {
    costSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('costSectionItemId', params.costSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSectionItemDelete$Json(params?: {
    costSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddResourceSectionItemPost
   */
  static readonly ApiCostManagerAddResourceSectionItemPostPath = '/api/CostManager/add-resource-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddResourceSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionItemPost$Plain$Response(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddResourceSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddResourceSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionItemPost$Plain(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<ResourceCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddResourceSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>) => r.body as ResourceCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddResourceSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionItemPost$Json$Response(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddResourceSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddResourceSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddResourceSectionItemPost$Json(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<ResourceCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddResourceSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceCostSectionItemDtoBaseResponse>) => r.body as ResourceCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateResourceSectionItemPut
   */
  static readonly ApiCostManagerUpdateResourceSectionItemPutPath = '/api/CostManager/update-resource-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateResourceSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionItemPut$Plain$Response(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateResourceSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateResourceSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionItemPut$Plain(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateResourceSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateResourceSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionItemPut$Json$Response(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateResourceSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateResourceSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateResourceSectionItemPut$Json(params?: {
    body?: ResourceCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateResourceSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteResourceSectionItemDelete
   */
  static readonly ApiCostManagerDeleteResourceSectionItemDeletePath = '/api/CostManager/delete-resource-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteResourceSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteResourceSectionItemDelete$Plain$Response(params?: {
    resourceCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteResourceSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('resourceCostSectionItemId', params.resourceCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteResourceSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteResourceSectionItemDelete$Plain(params?: {
    resourceCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteResourceSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteResourceSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteResourceSectionItemDelete$Json$Response(params?: {
    resourceCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteResourceSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('resourceCostSectionItemId', params.resourceCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteResourceSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteResourceSectionItemDelete$Json(params?: {
    resourceCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteResourceSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddSimpleSectionItemPost
   */
  static readonly ApiCostManagerAddSimpleSectionItemPostPath = '/api/CostManager/add-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionItemPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Plain$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionItemPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Plain(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<SimpleCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionItemPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>) => r.body as SimpleCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddSimpleSectionItemPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Json$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddSimpleSectionItemPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddSimpleSectionItemPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddSimpleSectionItemPost$Json(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<SimpleCostSectionItemDtoBaseResponse> {

    return this.apiCostManagerAddSimpleSectionItemPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SimpleCostSectionItemDtoBaseResponse>) => r.body as SimpleCostSectionItemDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateSimpleSectionItemPut
   */
  static readonly ApiCostManagerUpdateSimpleSectionItemPutPath = '/api/CostManager/update-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionItemPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Plain$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionItemPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Plain(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionItemPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateSimpleSectionItemPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Json$Response(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateSimpleSectionItemPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateSimpleSectionItemPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateSimpleSectionItemPut$Json(params?: {
    body?: SimpleCostSectionItemDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateSimpleSectionItemPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerDeleteSimpleSectionItemDelete
   */
  static readonly ApiCostManagerDeleteSimpleSectionItemDeletePath = '/api/CostManager/delete-simple-section-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSimpleSectionItemDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSimpleSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionItemId', params.simpleCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Plain(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSimpleSectionItemDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerDeleteSimpleSectionItemDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Json$Response(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerDeleteSimpleSectionItemDeletePath, 'delete');
    if (params) {
      rb.query('simpleCostSectionItemId', params.simpleCostSectionItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerDeleteSimpleSectionItemDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerDeleteSimpleSectionItemDelete$Json(params?: {
    simpleCostSectionItemId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerDeleteSimpleSectionItemDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetSectionsGet
   */
  static readonly ApiCostManagerGetSectionsGetPath = '/api/CostManager/get-sections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetSectionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetSectionsGet$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostSectionDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetSectionsGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetSectionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetSectionsGet$Plain(params?: {
    costManagerId?: number;
  }): Observable<CostSectionDtoListBaseResponse> {

    return this.apiCostManagerGetSectionsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoListBaseResponse>) => r.body as CostSectionDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetSectionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetSectionsGet$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostSectionDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetSectionsGetPath, 'get');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostSectionDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetSectionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetSectionsGet$Json(params?: {
    costManagerId?: number;
  }): Observable<CostSectionDtoListBaseResponse> {

    return this.apiCostManagerGetSectionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostSectionDtoListBaseResponse>) => r.body as CostSectionDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerAddMilestonePost
   */
  static readonly ApiCostManagerAddMilestonePostPath = '/api/CostManager/add-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Plain$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Plain(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<CostManagerMilestoneDtoBaseResponse> {

    return this.apiCostManagerAddMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>) => r.body as CostManagerMilestoneDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerAddMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Json$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerAddMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerAddMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerAddMilestonePost$Json(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<CostManagerMilestoneDtoBaseResponse> {

    return this.apiCostManagerAddMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoBaseResponse>) => r.body as CostManagerMilestoneDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetMilestonesPost
   */
  static readonly ApiCostManagerGetMilestonesPostPath = '/api/CostManager/get-milestones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetMilestonesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Plain$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetMilestonesPostPath, 'post');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetMilestonesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Plain(params?: {
    costManagerId?: number;
  }): Observable<CostManagerMilestoneDtoListBaseResponse> {

    return this.apiCostManagerGetMilestonesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>) => r.body as CostManagerMilestoneDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetMilestonesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Json$Response(params?: {
    costManagerId?: number;
  }): Observable<StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetMilestonesPostPath, 'post');
    if (params) {
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetMilestonesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetMilestonesPost$Json(params?: {
    costManagerId?: number;
  }): Observable<CostManagerMilestoneDtoListBaseResponse> {

    return this.apiCostManagerGetMilestonesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CostManagerMilestoneDtoListBaseResponse>) => r.body as CostManagerMilestoneDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateMilestonePost
   */
  static readonly ApiCostManagerUpdateMilestonePostPath = '/api/CostManager/update-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateMilestonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Plain$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateMilestonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Plain(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateMilestonePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateMilestonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Json$Response(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateMilestonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateMilestonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerUpdateMilestonePost$Json(params?: {
    body?: CostManagerMilestoneDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateMilestonePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerArchiveMilestoneDelete
   */
  static readonly ApiCostManagerArchiveMilestoneDeletePath = '/api/CostManager/archive-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveMilestoneDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Plain$Response(params?: {
    costManagerMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('costManagerMilestoneId', params.costManagerMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveMilestoneDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Plain(params?: {
    costManagerMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveMilestoneDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerArchiveMilestoneDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Json$Response(params?: {
    costManagerMilestoneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerArchiveMilestoneDeletePath, 'delete');
    if (params) {
      rb.query('costManagerMilestoneId', params.costManagerMilestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerArchiveMilestoneDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerArchiveMilestoneDelete$Json(params?: {
    costManagerMilestoneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerArchiveMilestoneDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetOverdueMilestonesGet
   */
  static readonly ApiCostManagerGetOverdueMilestonesGetPath = '/api/CostManager/get-overdue-milestones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetOverdueMilestonesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetOverdueMilestonesGet$Plain$Response(params?: {
    authorUserId?: number;
  }): Observable<StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetOverdueMilestonesGetPath, 'get');
    if (params) {
      rb.query('authorUserId', params.authorUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetOverdueMilestonesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetOverdueMilestonesGet$Plain(params?: {
    authorUserId?: number;
  }): Observable<MilestoneNotificationDtoListBaseResponse> {

    return this.apiCostManagerGetOverdueMilestonesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>) => r.body as MilestoneNotificationDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetOverdueMilestonesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetOverdueMilestonesGet$Json$Response(params?: {
    authorUserId?: number;
  }): Observable<StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetOverdueMilestonesGetPath, 'get');
    if (params) {
      rb.query('authorUserId', params.authorUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetOverdueMilestonesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetOverdueMilestonesGet$Json(params?: {
    authorUserId?: number;
  }): Observable<MilestoneNotificationDtoListBaseResponse> {

    return this.apiCostManagerGetOverdueMilestonesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MilestoneNotificationDtoListBaseResponse>) => r.body as MilestoneNotificationDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetNotificationMilestoneGet
   */
  static readonly ApiCostManagerGetNotificationMilestoneGetPath = '/api/CostManager/get-notification-milestone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetNotificationMilestoneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetNotificationMilestoneGet$Plain$Response(params?: {
    milestoneId?: number;
  }): Observable<StrictHttpResponse<MilestoneNotificationDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetNotificationMilestoneGetPath, 'get');
    if (params) {
      rb.query('milestoneId', params.milestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MilestoneNotificationDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetNotificationMilestoneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetNotificationMilestoneGet$Plain(params?: {
    milestoneId?: number;
  }): Observable<MilestoneNotificationDtoBaseResponse> {

    return this.apiCostManagerGetNotificationMilestoneGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MilestoneNotificationDtoBaseResponse>) => r.body as MilestoneNotificationDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetNotificationMilestoneGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetNotificationMilestoneGet$Json$Response(params?: {
    milestoneId?: number;
  }): Observable<StrictHttpResponse<MilestoneNotificationDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetNotificationMilestoneGetPath, 'get');
    if (params) {
      rb.query('milestoneId', params.milestoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MilestoneNotificationDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetNotificationMilestoneGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetNotificationMilestoneGet$Json(params?: {
    milestoneId?: number;
  }): Observable<MilestoneNotificationDtoBaseResponse> {

    return this.apiCostManagerGetNotificationMilestoneGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MilestoneNotificationDtoBaseResponse>) => r.body as MilestoneNotificationDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerSendMilestoneToAccountsPost
   */
  static readonly ApiCostManagerSendMilestoneToAccountsPostPath = '/api/CostManager/send-milestone-to-accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerSendMilestoneToAccountsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerSendMilestoneToAccountsPost$Plain$Response(params?: {
    senderName?: string;
    senderEmail?: string;
    uri?: string;
    body?: MilestoneNotificationDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerSendMilestoneToAccountsPostPath, 'post');
    if (params) {
      rb.query('senderName', params.senderName, {});
      rb.query('senderEmail', params.senderEmail, {});
      rb.query('uri', params.uri, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerSendMilestoneToAccountsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerSendMilestoneToAccountsPost$Plain(params?: {
    senderName?: string;
    senderEmail?: string;
    uri?: string;
    body?: MilestoneNotificationDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerSendMilestoneToAccountsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerSendMilestoneToAccountsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerSendMilestoneToAccountsPost$Json$Response(params?: {
    senderName?: string;
    senderEmail?: string;
    uri?: string;
    body?: MilestoneNotificationDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerSendMilestoneToAccountsPostPath, 'post');
    if (params) {
      rb.query('senderName', params.senderName, {});
      rb.query('senderEmail', params.senderEmail, {});
      rb.query('uri', params.uri, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerSendMilestoneToAccountsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCostManagerSendMilestoneToAccountsPost$Json(params?: {
    senderName?: string;
    senderEmail?: string;
    uri?: string;
    body?: MilestoneNotificationDto
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerSendMilestoneToAccountsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerLongTermForecastPost
   */
  static readonly ApiCostManagerLongTermForecastPostPath = '/api/CostManager/long-term-forecast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerLongTermForecastPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastPost$Plain$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<LongTermForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerLongTermForecastPostPath, 'post');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LongTermForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerLongTermForecastPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastPost$Plain(params?: {
    startDate?: string;
  }): Observable<LongTermForecastDtoBaseResponse> {

    return this.apiCostManagerLongTermForecastPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LongTermForecastDtoBaseResponse>) => r.body as LongTermForecastDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerLongTermForecastPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastPost$Json$Response(params?: {
    startDate?: string;
  }): Observable<StrictHttpResponse<LongTermForecastDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerLongTermForecastPostPath, 'post');
    if (params) {
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LongTermForecastDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerLongTermForecastPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerLongTermForecastPost$Json(params?: {
    startDate?: string;
  }): Observable<LongTermForecastDtoBaseResponse> {

    return this.apiCostManagerLongTermForecastPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LongTermForecastDtoBaseResponse>) => r.body as LongTermForecastDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerGetUsersEditingCmGet
   */
  static readonly ApiCostManagerGetUsersEditingCmGetPath = '/api/CostManager/get-users-editing-cm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetUsersEditingCmGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetUsersEditingCmGet$Plain$Response(params?: {
    currentUserId?: number;
    costManagerId?: number;
  }): Observable<StrictHttpResponse<Int64ListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetUsersEditingCmGetPath, 'get');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetUsersEditingCmGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetUsersEditingCmGet$Plain(params?: {
    currentUserId?: number;
    costManagerId?: number;
  }): Observable<Int64ListBaseResponse> {

    return this.apiCostManagerGetUsersEditingCmGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ListBaseResponse>) => r.body as Int64ListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerGetUsersEditingCmGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetUsersEditingCmGet$Json$Response(params?: {
    currentUserId?: number;
    costManagerId?: number;
  }): Observable<StrictHttpResponse<Int64ListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerGetUsersEditingCmGetPath, 'get');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
      rb.query('costManagerId', params.costManagerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerGetUsersEditingCmGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerGetUsersEditingCmGet$Json(params?: {
    currentUserId?: number;
    costManagerId?: number;
  }): Observable<Int64ListBaseResponse> {

    return this.apiCostManagerGetUsersEditingCmGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ListBaseResponse>) => r.body as Int64ListBaseResponse)
    );
  }

  /**
   * Path part for operation apiCostManagerUpdateUserEditingStatusPut
   */
  static readonly ApiCostManagerUpdateUserEditingStatusPutPath = '/api/CostManager/update-user-editing-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateUserEditingStatusPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerUpdateUserEditingStatusPut$Plain$Response(params?: {
    userId?: number;
    costManagerId?: number;
    isPending?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateUserEditingStatusPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('isPending', params.isPending, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateUserEditingStatusPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerUpdateUserEditingStatusPut$Plain(params?: {
    userId?: number;
    costManagerId?: number;
    isPending?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateUserEditingStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCostManagerUpdateUserEditingStatusPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerUpdateUserEditingStatusPut$Json$Response(params?: {
    userId?: number;
    costManagerId?: number;
    isPending?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CostManagerService.ApiCostManagerUpdateUserEditingStatusPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('costManagerId', params.costManagerId, {});
      rb.query('isPending', params.isPending, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCostManagerUpdateUserEditingStatusPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCostManagerUpdateUserEditingStatusPut$Json(params?: {
    userId?: number;
    costManagerId?: number;
    isPending?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiCostManagerUpdateUserEditingStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
