import { Component } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    // standalone : true,
    // imports    : [RouterOutlet, MatSnackBarModule],
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor()
    {
    }

}

export function getPropVal (object: any, path: string) {
    return path.split('.').reduce((o, i) => o[i], object)
  }

export function sortOnProperty<T> (items: T[], property: string, isAsc: boolean = true): T[] {
    var get = getPropVal;

    if (isAsc) {
        return items.sort((a, b) => (get(a, property) > get(b, property)) ? 1 : ((get(b, property) > get(a, property)) ? -1 : 0))
    } else {
        return items.sort((a, b) => (get(a, property) > get(b, property)) ? -1 : ((get(b, property) > get(a, property)) ? 1 : 0))
    }
}

export function sortOnSelf<T> (items: T[], isAsc: boolean = true): T[] {
    if (isAsc) {
        return items.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
    } else {
        return items.sort((a, b) => (a > b) ? -1 : ((b > a) ? 1 : 0))
    }
}

export function sumList(arr: number[]) {
    return arr?.reduce((prev, cur) => +prev + +cur, 0);
}

export function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
