/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';
import { ResourceDto } from '../models/resource-dto';
import { ResourceDtoBaseResponse } from '../models/resource-dto-base-response';
import { ResourceDtoListBaseResponse } from '../models/resource-dto-list-base-response';
import { ResourceDtoPagedResponseBaseResponse } from '../models/resource-dto-paged-response-base-response';

@Injectable({
  providedIn: 'root',
})
export class ResourceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiResourcePaginatedListGet
   */
  static readonly ApiResourcePaginatedListGetPath = '/api/Resource/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourcePaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourcePaginatedListGet$Plain$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourcePaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourcePaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourcePaginatedListGet$Plain(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ResourceDtoPagedResponseBaseResponse> {

    return this.apiResourcePaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>) => r.body as ResourceDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourcePaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourcePaginatedListGet$Json$Response(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourcePaginatedListGetPath, 'get');
    if (params) {
      rb.query('Month', params.Month, {});
      rb.query('Year', params.Year, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourcePaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourcePaginatedListGet$Json(params?: {
    Month?: number;
    Year?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ResourceDtoPagedResponseBaseResponse> {

    return this.apiResourcePaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoPagedResponseBaseResponse>) => r.body as ResourceDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceGetAllGet
   */
  static readonly ApiResourceGetAllGetPath = '/api/Resource/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetAllGet$Plain$Response(params?: {
    includeArchived?: boolean;
  }): Observable<StrictHttpResponse<ResourceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceGetAllGetPath, 'get');
    if (params) {
      rb.query('includeArchived', params.includeArchived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetAllGet$Plain(params?: {
    includeArchived?: boolean;
  }): Observable<ResourceDtoListBaseResponse> {

    return this.apiResourceGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoListBaseResponse>) => r.body as ResourceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetAllGet$Json$Response(params?: {
    includeArchived?: boolean;
  }): Observable<StrictHttpResponse<ResourceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceGetAllGetPath, 'get');
    if (params) {
      rb.query('includeArchived', params.includeArchived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetAllGet$Json(params?: {
    includeArchived?: boolean;
  }): Observable<ResourceDtoListBaseResponse> {

    return this.apiResourceGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoListBaseResponse>) => r.body as ResourceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceSearchAllGet
   */
  static readonly ApiResourceSearchAllGetPath = '/api/Resource/search-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceSearchAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceSearchAllGet$Plain$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ResourceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceSearchAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceSearchAllGet$Plain(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ResourceDtoListBaseResponse> {

    return this.apiResourceSearchAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoListBaseResponse>) => r.body as ResourceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceSearchAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceSearchAllGet$Json$Response(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<StrictHttpResponse<ResourceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceSearchAllGetPath, 'get');
    if (params) {
      rb.query('searchTerm', params.searchTerm, {});
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceSearchAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceSearchAllGet$Json(params?: {
    searchTerm?: string;
    archived?: boolean;
  }): Observable<ResourceDtoListBaseResponse> {

    return this.apiResourceSearchAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoListBaseResponse>) => r.body as ResourceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceGetResourcePost
   */
  static readonly ApiResourceGetResourcePostPath = '/api/Resource/get-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceGetResourcePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetResourcePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<ResourceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceGetResourcePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceGetResourcePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetResourcePost$Plain(params?: {
    id?: number;
  }): Observable<ResourceDtoBaseResponse> {

    return this.apiResourceGetResourcePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoBaseResponse>) => r.body as ResourceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceGetResourcePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetResourcePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<ResourceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceGetResourcePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceGetResourcePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiResourceGetResourcePost$Json(params?: {
    id?: number;
  }): Observable<ResourceDtoBaseResponse> {

    return this.apiResourceGetResourcePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoBaseResponse>) => r.body as ResourceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceAddResourcePost
   */
  static readonly ApiResourceAddResourcePostPath = '/api/Resource/add-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceAddResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceAddResourcePost$Plain$Response(params?: {
    body?: ResourceDto
  }): Observable<StrictHttpResponse<ResourceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceAddResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceAddResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceAddResourcePost$Plain(params?: {
    body?: ResourceDto
  }): Observable<ResourceDtoBaseResponse> {

    return this.apiResourceAddResourcePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoBaseResponse>) => r.body as ResourceDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceAddResourcePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceAddResourcePost$Json$Response(params?: {
    body?: ResourceDto
  }): Observable<StrictHttpResponse<ResourceDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceAddResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceAddResourcePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceAddResourcePost$Json(params?: {
    body?: ResourceDto
  }): Observable<ResourceDtoBaseResponse> {

    return this.apiResourceAddResourcePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceDtoBaseResponse>) => r.body as ResourceDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceUpdateResourcePost
   */
  static readonly ApiResourceUpdateResourcePostPath = '/api/Resource/update-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceUpdateResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceUpdateResourcePost$Plain$Response(params?: {
    body?: ResourceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceUpdateResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceUpdateResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceUpdateResourcePost$Plain(params?: {
    body?: ResourceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiResourceUpdateResourcePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceUpdateResourcePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceUpdateResourcePost$Json$Response(params?: {
    body?: ResourceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceUpdateResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceUpdateResourcePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceUpdateResourcePost$Json(params?: {
    body?: ResourceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiResourceUpdateResourcePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiResourceToggleArchiveResourcePost
   */
  static readonly ApiResourceToggleArchiveResourcePostPath = '/api/Resource/toggle-archive-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceToggleArchiveResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceToggleArchiveResourcePost$Plain$Response(params?: {
    isArchive?: boolean;
    body?: ResourceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceToggleArchiveResourcePostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceToggleArchiveResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceToggleArchiveResourcePost$Plain(params?: {
    isArchive?: boolean;
    body?: ResourceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiResourceToggleArchiveResourcePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiResourceToggleArchiveResourcePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceToggleArchiveResourcePost$Json$Response(params?: {
    isArchive?: boolean;
    body?: ResourceDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ApiResourceToggleArchiveResourcePostPath, 'post');
    if (params) {
      rb.query('isArchive', params.isArchive, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiResourceToggleArchiveResourcePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiResourceToggleArchiveResourcePost$Json(params?: {
    isArchive?: boolean;
    body?: ResourceDto
  }): Observable<BooleanBaseResponse> {

    return this.apiResourceToggleArchiveResourcePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
