/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { CurrencyType } from '../models/currency-type';
import { DateTimeInvoiceDtoIGroupingListBaseResponse } from '../models/date-time-invoice-dto-i-grouping-list-base-response';
import { InvoiceDtoListBaseResponse } from '../models/invoice-dto-list-base-response';
import { XeroInvoicesBaseResponse } from '../models/xero-invoices-base-response';
import { XeroPurchaseOrdersBaseResponse } from '../models/xero-purchase-orders-base-response';

@Injectable({
  providedIn: 'root',
})
export class XeroService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiXeroAuthenticateGet
   */
  static readonly ApiXeroAuthenticateGetPath = '/api/Xero/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroAuthenticateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAuthenticateGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroAuthenticateGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroAuthenticateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAuthenticateGet$Plain(params?: {
  }): Observable<BooleanBaseResponse> {

    return this.apiXeroAuthenticateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroAuthenticateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAuthenticateGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroAuthenticateGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroAuthenticateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAuthenticateGet$Json(params?: {
  }): Observable<BooleanBaseResponse> {

    return this.apiXeroAuthenticateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiXeroInvoicesGet
   */
  static readonly ApiXeroInvoicesGetPath = '/api/Xero/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroInvoicesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroInvoicesGet$Plain$Response(params?: {
    since?: string;
  }): Observable<StrictHttpResponse<XeroInvoicesBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroInvoicesGetPath, 'get');
    if (params) {
      rb.query('since', params.since, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<XeroInvoicesBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroInvoicesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroInvoicesGet$Plain(params?: {
    since?: string;
  }): Observable<XeroInvoicesBaseResponse> {

    return this.apiXeroInvoicesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<XeroInvoicesBaseResponse>) => r.body as XeroInvoicesBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroInvoicesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroInvoicesGet$Json$Response(params?: {
    since?: string;
  }): Observable<StrictHttpResponse<XeroInvoicesBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroInvoicesGetPath, 'get');
    if (params) {
      rb.query('since', params.since, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<XeroInvoicesBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroInvoicesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroInvoicesGet$Json(params?: {
    since?: string;
  }): Observable<XeroInvoicesBaseResponse> {

    return this.apiXeroInvoicesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<XeroInvoicesBaseResponse>) => r.body as XeroInvoicesBaseResponse)
    );
  }

  /**
   * Path part for operation apiXeroUpcomingInvoicesGet
   */
  static readonly ApiXeroUpcomingInvoicesGetPath = '/api/Xero/upcoming-invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroUpcomingInvoicesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroUpcomingInvoicesGet$Plain$Response(params?: {
    monthStart?: string;

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroUpcomingInvoicesGetPath, 'get');
    if (params) {
      rb.query('monthStart', params.monthStart, {});
      rb.query('currencyType', params.currencyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroUpcomingInvoicesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroUpcomingInvoicesGet$Plain(params?: {
    monthStart?: string;

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<DateTimeInvoiceDtoIGroupingListBaseResponse> {

    return this.apiXeroUpcomingInvoicesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>) => r.body as DateTimeInvoiceDtoIGroupingListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroUpcomingInvoicesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroUpcomingInvoicesGet$Json$Response(params?: {
    monthStart?: string;

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroUpcomingInvoicesGetPath, 'get');
    if (params) {
      rb.query('monthStart', params.monthStart, {});
      rb.query('currencyType', params.currencyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroUpcomingInvoicesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroUpcomingInvoicesGet$Json(params?: {
    monthStart?: string;

    /**
     * 0 &#x3D; GBP
     * 
     * 1 &#x3D; USD
     * 
     * 2 &#x3D; EUR
     */
    currencyType?: CurrencyType;
  }): Observable<DateTimeInvoiceDtoIGroupingListBaseResponse> {

    return this.apiXeroUpcomingInvoicesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeInvoiceDtoIGroupingListBaseResponse>) => r.body as DateTimeInvoiceDtoIGroupingListBaseResponse)
    );
  }

  /**
   * Path part for operation apiXeroBillsGet
   */
  static readonly ApiXeroBillsGetPath = '/api/Xero/bills';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroBillsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroBillsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroBillsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroBillsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroBillsGet$Plain(params?: {
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiXeroBillsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroBillsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroBillsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<InvoiceDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroBillsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoiceDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroBillsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroBillsGet$Json(params?: {
  }): Observable<InvoiceDtoListBaseResponse> {

    return this.apiXeroBillsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InvoiceDtoListBaseResponse>) => r.body as InvoiceDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiXeroPurchaseOrdersGet
   */
  static readonly ApiXeroPurchaseOrdersGetPath = '/api/Xero/purchase-orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroPurchaseOrdersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroPurchaseOrdersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<XeroPurchaseOrdersBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroPurchaseOrdersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<XeroPurchaseOrdersBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroPurchaseOrdersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroPurchaseOrdersGet$Plain(params?: {
  }): Observable<XeroPurchaseOrdersBaseResponse> {

    return this.apiXeroPurchaseOrdersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<XeroPurchaseOrdersBaseResponse>) => r.body as XeroPurchaseOrdersBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroPurchaseOrdersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroPurchaseOrdersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<XeroPurchaseOrdersBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroPurchaseOrdersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<XeroPurchaseOrdersBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroPurchaseOrdersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroPurchaseOrdersGet$Json(params?: {
  }): Observable<XeroPurchaseOrdersBaseResponse> {

    return this.apiXeroPurchaseOrdersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<XeroPurchaseOrdersBaseResponse>) => r.body as XeroPurchaseOrdersBaseResponse)
    );
  }

  /**
   * Path part for operation apiXeroAccountsGet
   */
  static readonly ApiXeroAccountsGetPath = '/api/Xero/accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroAccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAccountsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroAccountsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroAccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAccountsGet$Plain(params?: {
  }): Observable<BooleanBaseResponse> {

    return this.apiXeroAccountsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiXeroAccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAccountsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, XeroService.ApiXeroAccountsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiXeroAccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiXeroAccountsGet$Json(params?: {
  }): Observable<BooleanBaseResponse> {

    return this.apiXeroAccountsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
