/* tslint:disable */
/* eslint-disable */

/**
 * 1 = ResUHDHDR
 * 
 * 2 = Res4K
 * 
 * 3 = Res1080p
 * 
 * 4 = Res720p
 * 
 * 5 = ResOther
 */
export enum Resolution {
  ResUHDHDR = 1,
  Res4K = 2,
  Res1080p = 3,
  Res720p = 4,
  ResOther = 5
}
